import React from 'react';
import { injectIntl } from 'react-intl';

const Input = ({ intl}) => {
   // console.log(intl);
    //debugger
    return(<div>
        <input  name="name" type="text"  placeholder={intl.formatMessage({id: 'contact_form_name'})} required/>,
    <input type="email" name="email" placeholder={intl.formatMessage({id: 'contact_form_email'})} required/>
    <input  name="name"  type="phone" placeholder={intl.formatMessage({id: 'contact_form_phone'})} required/>
    <textarea type="text" name="message"
        id=""
        cols="30"
        rows="10"placeholder={intl.formatMessage({id: 'contact_form_message'})} required ></textarea>
        </div>)
};

export default injectIntl(Input)