import { LOCALES } from '../constants';

export default {
  [LOCALES.ENGLISH]: {
    'nav_home':'home',
    'nav_about':'about',
    'nav_services':'services',
    'nav_product':'products',
    'nav_contact':'contact',

    'nav_home_mobile':'home',
    'nav_about_mobile':'about',
    'nav_services_mobile':'services',
    'nav_product_mobile':'products',
    'nav_contact_mobile':'contact',

    'main_heading': 'International Factory',
    'main_heading_paragraph': 'Cleaning & Industrial Chemical Manufacturer',
    'main_heading_button': 'Our Products',
     
    'about_title':'ABOUT',

    'about_pagraph_one':' D.D.C International Factory F.Z.C, a Cleaning & Industrial Chemical Manufacturer, is involved in both Manufacturing and Trading activities.',

    'about_pagraph_secoend':'We are the largest manufacturers of hygiene products in the U.A.E.High quality products are being manufactured in our Saif Zone Sharjah facility and being delivered to United Arab Emirates local market as well as exported worldwide catering to different  industries in GCC and Africa.',
   
    'about_pagraph_three':' “D.D.C International Factory F.Z.C is recognized as one of the leading Manufacturers of Cleaning Chemicals in the UAE | GCC | MIDDLEAST”',
    
    'services_title':'SERVICES',
    'service_header_first':'Customer Care',
    'service_desc_first':'We give top most priority to our customers and thus endeavor to offer the highest level of satisfaction to them.',

    'service_header_second':'Eco-friendly',
    'service_desc_second':'We always go for the quality of the product rather than going for its quantity. Being environment friendly, our products meet both environmental regulations as well as increasing ecologically aware customers.',
    
    'service_header_third':'Latest Market Requirements',
    'service_desc_third':'Highly qualified professionals and quality experts associated with us are the most integral part of the company, who enable us to keep pace with the latest market requirements.',

    'achivement_title':'PRODUCT CAPACITY PER DAY',
    'achivement_title_first':'Home Care',
    'achivement_title_seconed':'Personal Care',
    'achivement_title_third':'Auto Care',
    'achivement_unit':'MT',


    'products_title':'PRODUCTS',
    
    'products_description':'Description:',
    'products_directions':'Directions:',
    'products_caution':'Caution:',
    'products_ingredients':'Ingredients:',
    'products_safetyWarning':'Safety Warnings:',

    'products_next':'Next',
    'products_previous':'Previous',
    'products_follow':'Follow us :',
    'products_more':'MORE PRODUCTS',
    

    'products_one_title':'Antiseptic & Disinfectant 500ml',
    'products_one_desc':'Care Plus Antiseptic & Disinfectant liquid',
    'products_one_dir':'Use concentrated for lavatories, sinks and drains. And dilute for Medical use, personal care, and laundry',
    'products_one_caution':'Although a gentle antiseptic, do not use for babies expect on medical advice.',
    'products_one_ingredients':'Chloroxylenol, Pine Oil, Isopropyl Alcohol, Castor Oil, Caramel, Deionized Water',
    'products_one_safetyWarning':'Best use before 30 months from production date seen on pack. for external use only & store in a cool and dry place bellow 30 ◦cl. Keep away from children. Not edible, if swollen, it is advised to see a doctor immediately.',



    'products_two_title':'Antiseptic & Disinfectant 5L',
    'products_two_desc':'Care Plus Antiseptic & Disinfectant liquid',
    'products_two_dir':'Use concentrated for lavatories, sinks and drains. And dilute for Medical use, personal care, and laundry',
    'products_two_caution':'Although a gentle antiseptic, do not use for babies expect on medical advice.',
    'products_two_ingredients':'Chloroxylenol, Pine Oil, Isopropyl Alcohol, Castor Oil, Caramel, Deionized Water',
    'products_two_safetyWarning':'Best use before 30 months from production date seen on pack. for external use only & store in a cool and dry place bellow 30 ◦cl. Keep away from children. Not edible, if swollen, it is advised to see a doctor immediately.',




    'products_three_title':'Cloromax 1L',
    'products_three_desc':'Whitens, Removes Stains, Cleans and Disinfects',
    'products_three_dir':'Suitable for use in: Toilet, Bathroom, Kitchen, Floors, Tiles, Children\'s Plastic Toys. Use on hard,       nonporous surfaces. Anti-yellowing formula.',
    'products_three_caution':'The product should be diluted with water according to the instructions on the package. Do not use this product without diluting it with water to clean the surfaces. It is also advisable to wear rubber gloves when cleaning.',
    'products_three_ingredients':'Sodium Hypochlorite 6.15%, Other Ingredients 93.85%',
    'products_three_safetyWarning':'Keep out of the reach of children. Do not mix together with toilet cleaners or with products containing acids or ammonia. May release dangerous gases (chlorine). If gets into eyes; rinse them with water for 15 minutes. If irritation persists, call a doctor',



    'products_four_title':'Cloromax 1 gallon',
    'products_four_desc':'Whitens, Removes Stains, Cleans and Disinfects',
    'products_four_dir':'Suitable for use in: Toilet, Bathroom, Kitchen, Floors, Tiles, Children\'s Plastic Toys. Use on hard, nonporous surfaces. Anti-yellowing formula.',
    'products_four_caution':'The product should be diluted with water according to the instructions on the package. Do not use this product without diluting it with water to clean the surfaces. It is also advisable to wear rubber gloves when cleaning.',
    'products_four_ingredients':'Sodium Hypochlorite 6.15%, Other Ingredients 93.85%',
    'products_four_safetyWarning':'Keep out of the reach of children. Do not mix together with toilet cleaners or with products containing acids or ammonia. May release dangerous gases (chlorine). If gets into eyes; rinse them with water for 15 minutes. If irritation persists, call a doctor',


    'products_five_title':'Flash 1L- Original',
    'products_five_desc':'Care Plus Flash, 1 Liter Toilet Cleaner. Maximum bowl coverage. Removes through stains. -Kills 99.9% of Germs',
    'products_five_dir':'Squeeze pads on sides of the cap and unscrew to remove the cap. Squeeze and apply around the inside of bowl. For the best results leave for 30 minutes then brush and flush.',
    'products_five_caution':'We recommend you to wear gloves while you clean your toilet bowl.',
    'products_five_ingredients':'Per 100g of liquid: 6.13g of Hydrochloric Acid, Less than 5% of non-ionic Surfactant, Disinfectant, Colorant. Contains less than 5% of cationic surfactants, Perfume.',
    'products_five_safetyWarning':'Danger contains hydrochloric acid, causes severe skin burns and eye damage. Do not breath fumes. Keep away from children. If swollen, rinse mouth. DO NOT induce vomiting. Immediately call a POISON CENTER or doctor.',


    'products_six_title':'Flash 1L- Flower Perfume',
    'products_six_desc':'Care Plus Flash, 1 Liter Toilet Cleaner. Maximum bowl coverage. Removes through stains. -Kills 99.9% of Germs',
    'products_six_dir':'Squeeze pads on sides of the cap and unscrew to remove the cap. Squeeze and apply around the inside of bowl. For the best results leave for 30 minutes then brush and flush.',
    'products_six_caution':'We recommend you to wear gloves while you clean your toilet bowl.',
    'products_six_ingredients':'Per 100g of liquid: 6.13g of Hydrochloric Acid, Less than 5% of non-ionic Surfactant, Disinfectant, Colorant. Contains less than 5% of cationic surfactants, Perfume.',
    'products_six_safetyWarning':'Danger contains hydrochloric acid, causes severe skin burns and eye damage. Do not breath fumes. Keep away from children. If swollen, rinse mouth. DO NOT induce vomiting. Immediately call a POISON CENTER or doctor.',



    'products_seven_title':'Dish Wash Liquid -Orange',
    'products_seven_desc':'Care Plus, Antibacterial Dish Wash Liquid, 1Liter, Orange Flavor',
    'products_seven_dir':'add a little bit of care plus dishwashing liquid in a small bowl of water or directly on your sponge and start washing to get perfectly clean and sparkling dishes.',
    'products_seven_caution':'Protect from direct sunlight.',
    'products_seven_ingredients':'ingredients: 12-30% Labsa, Sodium, Hydroxide, Sodium Lauryl Ether Sulphate, CDEA, CAPB, Glycerin, Benzisothiazolinone, Methylchloroisothiazolinone, Methylisothiazolinone, Antibacterial Agent, Perfume, Dye, Limonene, Linalool, Citral.',
    'products_seven_safetyWarning':'Best use before 30 months from production date seen on pack. for external use only & store in a cool and dry place bellow 30 ◦cl. Keep away from children. Not edible, if swollen, it is advised to see a doctor immediately.',



    'products_eight_title':'Dish Wash Liquid -Lemon',
    'products_eight_desc':'Care Plus, Antibacterial Dish Wash Liquid, 1Liter, Lemon Flavor',
    'products_eight_dir':'add a little bit of care plus dishwashing liquid in a small bowl of water or directly on your sponge and start washing to get perfectly clean and sparkling dishes.',
    'products_eight_caution':'Protect from direct sunlight.',
    'products_eight_ingredients':'ingredients: 12-30% Labsa, Sodium, Hydroxide, Sodium Lauryl Ether Sulphate, CDEA, CAPB, Glycerin, Benzisothiazolinone, Methylchloroisothiazolinone, Methylisothiazolinone, Antibacterial Agent, Perfume, Dye, Limonene, Linalool, Citral.',
    'products_eight_safetyWarning':'Best use before 30 months from production date seen on pack. for external use only & store in a cool and dry place bellow 30 ◦cl. Keep away from children. Not edible, if swollen, it is advised to see a doctor immediately.',



    'products_nine_title':'Hand Sanitizer 500ml - Original',
    'products_nine_desc':'Care Plus Care plus Hand Sanitizer kills 99.99% of germs instantly without water leaving your hands feeling refreshed. Carry and use whenever, where ever: car, office, picnics, nappy change, camping, travel, sports etc.',
    'products_nine_dir':'Squeeze a small amount of Careplus Hand Sanitizer in your palm then briskly rub hands together until dry. Store Below 30 C.',
    'products_nine_caution':'HIGHLY FLAMMABLE. Keep away from the fire and flame. For external use only. In case of eye contact, flush with water. Discontinue use if skin irritation occurs. Id condition persists, consult a doctor. Keep out of reach of children. If swallowed contact a Poisons Information Center.',
    'products_nine_ingredients':'Acrylates/C10-30 Alkyl Acrylate, Crosspolymer, PEG/PPG-17/16 Copolymer, Alcohol Denat, Aqua, Mono Propylene Glycol, Triethanoiamine, Parfum, Linalool, Hexyl Cinnamai, Hydroxycitronellai, Limonene.',
    'products_nine_safetyWarning':'Keep away from fire or flame. For external use only. When using this product do not use in or near the eyes. In case of contact, rinse eyes thoroughly with water. Stop use and ask a doctor if irritation or rash appears or lasts. Keep out of reach of children. If swallowed, get medical help or contact a poison control center right away.',


    'products_ten_title':'Hand Sanitizer 500ml - Herbal',
    'products_ten_desc':'Care plus Hand Sanitizer kills 99.99% of germs instantly without water leaving your hands feeling refreshed. Carry and use whenever, where ever: car, office, picnics, nappy change, camping, travel, sports etc.',
    'products_ten_dir':'Squeeze a small amount of Careplus Hand Sanitizer in your palm then briskly rub hands together until dry. Store Below 30 C.',
    'products_ten_caution':'HIGHLY FLAMMABLE. Keep away from the fire and flame. For external use only. In case of eye contact, flush with water. Discontinue use if skin irritation occurs. Id condition persists, consult a doctor. Keep out of reach of children. If swallowed contact a Poisons Information Center.',
    'products_ten_ingredients':'Acrylates/C10-30 Alkyl Acrylate, Crosspolymer, PEG/PPG-17/16 Copolymer, Alcohol Denat, Aqua, Mono Propylene Glycol, Triethanoiamine, Parfum, Linalool, Hexyl Cinnamai, Hydroxycitronellai, Limonene.',
    'products_ten_safetyWarning':'Keep away from fire or flame. For external use only. When using this product do not use in or near the eyes. In case of contact, rinse eyes thoroughly with water. Stop use and ask a doctor if irritation or rash appears or lasts. Keep out of reach of children. If swallowed, get medical help or contact a poison control center right away.',



    'products_eleven_title':'Hand Sanitizer 60ml - Original',
    'products_eleven_desc':'Care plus Hand Sanitizer kills 99.99% of germs instantly without water leaving your hands feeling refreshed. Carry and use whenever, where ever: car, office, picnics, nappy change, camping, travel, sports etc.',
    'products_eleven_dir':'Squeeze a small amount of Careplus Hand Sanitizer in your palm then briskly rub hands together until dry. Store Below 30 C.',
    'products_eleven_caution':'HIGHLY FLAMMABLE. Keep away from the fire and flame. For external use only. In case of eye contact, flush with water. Discontinue use if skin irritation occurs. Id condition persists, consult a doctor. Keep out of reach of children. If swallowed contact a Poisons Information Center.',
    'products_eleven_ingredients':'Acrylates/C10-30 Alkyl Acrylate, Crosspolymer, PEG/PPG-17/16 Copolymer, Alcohol Denat, Aqua, Mono Propylene Glycol, Triethanoiamine, Parfum, Linalool, Hexyl Cinnamai, Hydroxycitronellai, Limonene.',
    'products_eleven_safetyWarning':'Keep away from fire or flame. For external use only. When using this product do not use in or near the eyes. In case of contact, rinse eyes thoroughly with water. Stop use and ask a doctor if irritation or rash appears or lasts. Keep out of reach of children. If swallowed, get medical help or contact a poison control center right away.',



    'products_twelve_title':'Glass Cleaner 650ml',
    'products_twelve_desc':'Care Plus providing glass and surface cleaner with three powerful action shine system: 1-Lifts off the greasy residues 2-Removes it all 3-Lives a sparkling shine with no streaks.',
    'products_twelve_dir':'Usage areas is suitable for: Smooth and shiny surfaces, Countertops, Glass tables, Chrome, Appliances, Tiles, Plastics.',
    'products_twelve_caution':'Not suitable for: Copper and brass surfaces, Polished marble and grout, Wooden surfaces, Waked furniture.',
    'products_twelve_ingredients':'4.0% isopropyl alcohol, 1% ethylene glycol monobutyl ether, 0.1% sodium lauryl sulfate (a surfactant), calcium (Ca) 0.01%, tetrasodium pyrophosphate (a water softener), 0.05% of 28% ammonia, 1% of a dye solution, and 0.01% perfume.',
    'products_twelve_safetyWarning':'Best use before 30 months from production date seen on pack. for external use only & store in a cool and dry place bellow 30 ◦cl. Keep away from children. Not edible, if swollen, it is advised to see a doctor immediately.',



    'products_thirteen_title':'Glass Cleaner 5L',
    'products_thirteen_desc':'Care Plus providing glass and surface cleaner with powerful 3- action shine system 1-	Lifts off the greasy residues2-	Removes it all 3-	Lives a sparkling shine with no streaks.',
    'products_thirteen_dir':'Usage areas is suitable for: Smooth and shiny surfaces, Countertops, Glass tables, Chrome, Appliances, Tiles, Plastics.',
    'products_thirteen_caution':'Not suitable for: Copper and brass surfaces, Polished marble and grout, Wooden surfaces, Waked furniture.',
    'products_thirteen_ingredients':'4.0% isopropyl alcohol, 1% ethylene glycol monobutyl ether, 0.1% sodium lauryl sulfate (a surfactant), calcium (Ca) 0.01%, tetrasodium pyrophosphate (a water softener), 0.05% of 28% ammonia, 1% of a dye solution, and 0.01% perfume.',
    'products_thirteen_safetyWarning':'Best use before 30 months from production date seen on pack. for external use only & store in a cool and dry place bellow 30 ◦cl. Keep away from children. Not edible, if swollen, it is advised to see a doctor immediately.',


    'products_fourteen_title':'Antiseptic &Disinfectant 750ml',
    'products_fourteen_desc':'Care Plus Antiseptic & Disinfectant liquid',
    'products_fourteen_dir':'Use concentrated for lavatories, sinks and drains. And dilute for Medical use, personal care, and laundry',
    'products_fourteen_caution':'Although a gentle antiseptic, do not use for babies expect on medical advice.',
    'products_fourteen_ingredients':'Chloroxylenol, Pine Oil, Isopropyl Alcohol, Castor Oil, Caramel, Deionized Water',
    'products_fourteen_safetyWarning':'Best use before 30 months from production date seen on pack. for external use only & store in a cool and dry place bellow 30 ◦cl. Keep away from children. Not edible, if swollen, it is advised to see a doctor immediately.',


    'products_fifteen_title':'Floor Cleaner & Disinfectant',
    'products_fifteen_desc':'Care Plus Floor Cleaner Antibacterial Lemon  5 liter. Innovative and universal multi-purpose liquid. Intended for cleaning various surfaces, including wooden, lacquered, ceramic and plastic, floor panels, tiles, window sills, kitchen furniture etc',
    'products_fifteen_dir':'add a little bit of care plus floor cleaner liquid in a small bowl of water or directly on your sponge and start washing to get perfectly clean and sparkling dishes.',
    'products_fifteen_caution':'Protect from direct sunlight.',
    'products_fifteen_ingredients':'ingredients: 12-30% Labsa, Sodium, Hydroxide, Sodium Lauryl Ether Sulphate, CDEA, CAPB, Glycerin, Benzisothiazolinone, Methylchloroisothiazolinone, Methylisothiazolinone, Antibacterial Agent, Perfume, Dye, Limonene, Linalool, Citral',
    'products_fifteen_safetyWarning':'Best use before 30 months from production date seen on pack. for external use only & store in a cool and dry place bellow 30 ◦cl. Keep away from children. Not edible, if swollen, it is advised to see a doctor immediately.',


    'products_sixteen_title':'Dish Wash Liquid-Apple-1L',
    'products_sixteen_desc':'Care Plus, Antibacterial Dish Wash Liquid, 1Liter, Apple Flavor',
    'products_sixteen_dir':'add a little bit of care plus dishwashing liquid in a small bowl of water or directly on your sponge and start washing to get perfectly clean and sparkling dishes.',
    'products_sixteen_caution':'Protect from direct sunlight.',
    'products_sixteen_ingredients':'12-30% Labsa, Sodium, Hydroxide, Sodium Lauryl Ether Sulphate, CDEA, CAPB, Glycerin, Benzisothiazolinone, Methylchloroisothiazolinone, Methylisothiazolinone, Antibacterial Agent, Perfume, Dye, Limonene, Linalool, Citral',
    'products_sixteen_safetyWarning':'Best use before 30 months from production date seen on pack. for external use only & store in a cool and dry place bellow 30 ◦cl. Keep away from children. Not edible, if swollen, it is advised to see a doctor immediately.',


    'products_seventeen_title':'Dish Wash Liquid-Apple-500ml',
    'products_seventeen_desc':'Care Plus, Antibacterial Dish Wash Liquid, 1Liter, Apple Flavor',
    'products_seventeen_dir':'add a little bit of care plus dishwashing liquid in a small bowl of water or directly on your sponge and start washing to get perfectly clean and sparkling dishes.',
    'products_seventeen_caution':'Protect from direct sunlight.',
    'products_seventeen_ingredients':'ingredients: 12-30% Labsa, Sodium, Hydroxide, Sodium Lauryl Ether Sulphate, CDEA, CAPB, Glycerin, Benzisothiazolinone, Methylchloroisothiazolinone, Methylisothiazolinone, Antibacterial Agent, Perfume, Dye, Limonene, Linalool, Citral',
    'products_seventeen_safetyWarning':'Best use before 30 months from production date seen on pack. for external use only & store in a cool and dry place bellow 30 ◦cl. Keep away from children. Not edible, if swollen, it is advised to see a doctor immediately.',



    'products_eightteen_title':'Dish Wash Liquid-Lemon-5L',
    'products_eightteen_desc':'Care Plus, Antibacterial Dish Wash Liquid, 5Liter, Lemon Flavor',
    'products_eightteen_dir':'add a little bit of care plus dishwashing liquid in a small bowl of water or directly on your sponge and start washing to get perfectly clean and sparkling dishes.',
    'products_eightteen_caution':'Protect from direct sunlight.',
    'products_eightteen_ingredients':'ingredients: 12-30% Labsa, Sodium, Hydroxide, Sodium Lauryl Ether Sulphate, CDEA, CAPB, Glycerin, Benzisothiazolinone, Methylchloroisothiazolinone, Methylisothiazolinone, Antibacterial Agent, Perfume, Dye, Limonene, Linalool, Citral.',
    'products_eightteen_safetyWarning':'Best use before 30 months from production date seen on pack. for external use only & store in a cool and dry place bellow 30 ◦cl. Keep away from children. Not edible, if swollen, it is advised to see a doctor immediately.',


    'products_nineteen_title':'Dish Wash Liquid-strawberry-5L',
    'products_nineteen_desc':'Care Plus, Antibacterial Dish Wash Liquid, 5Liter, strawberry Flavor',
    'products_nineteen_dir':'add a little bit of care plus dishwashing liquid in a small bowl of water or directly on your sponge and start washing to get perfectly clean and sparkling dishes.',
    'products_nineteen_caution':'Protect from direct sunlight.',
    'products_nineteen_ingredients':'ingredients: 12-30% Labsa, Sodium, Hydroxide, Sodium Lauryl Ether Sulphate, CDEA, CAPB, Glycerin, Benzisothiazolinone, Methylchloroisothiazolinone, Methylisothiazolinone, Antibacterial Agent, Perfume, Dye, Limonene, Linalool, Citral.',
    'products_nineteen_safetyWarning':'Best use before 30 months from production date seen on pack. for external use only & store in a cool and dry place bellow 30 ◦cl. Keep away from children. Not edible, if swollen, it is advised to see a doctor immediately.',


    'products_twenty_title':'Dish Wash Liquid-strawberry-500ml',
    'products_twenty_desc':'Care Plus, Antibacterial Dish Wash Liquid, 500ml, strawberry Flavor',
    'products_twenty_dir':'add a little bit of care plus dishwashing liquid in a small bowl of water or directly on your sponge and start washing to get perfectly clean and sparkling dishes.',
    'products_twenty_caution':'Protect from direct sunlight.',
    'products_twenty_ingredients':'ingredients: 12-30% Labsa, Sodium, Hydroxide, Sodium Lauryl Ether Sulphate, CDEA, CAPB, Glycerin, Benzisothiazolinone, Methylchloroisothiazolinone, Methylisothiazolinone, Antibacterial Agent, Perfume, Dye, Limonene, Linalool, Citral.',
    'products_twenty_safetyWarning':'Best use before 30 months from production date seen on pack. for external use only & store in a cool and dry place bellow 30 ◦cl. Keep away from children. Not edible, if swollen, it is advised to see a doctor immediately.',


    'products_twentyone_title':'Hand Sanitizer-5L- Original',
    'products_twentyone_desc':'Care plus Hand Sanitizer kills 99.99% of germs instantly without water leaving your hands feeling refreshed. Carry and use whenever, where ever: car, office, picnics, nappy change, camping, travel, sports etc.',
    'products_twentyone_dir':'Squeeze a small amount of Careplus Hand Sanitizer in your palm then briskly rub hands together until dry. Store Below 30 C.',
    'products_twentyone_caution':'HIGHLY FLAMMABLE. Keep away from the fire and flame. For external use only. In case of eye contact, flush with water. Discontinue use if skin irritation occurs. Id condition persists, consult a doctor. Keep out of reach of children. If swallowed contact a Poisons Information Center.',
    'products_twentyone_ingredients':'Acrylates/C10-30 Alkyl Acrylate, Crosspolymer, PEG/PPG-17/16 Copolymer, Alcohol Denat, Aqua, Mono Propylene Glycol, Triethanoiamine, Parfum, Linalool, Hexyl Cinnamai, Hydroxycitronellai, Limonene.',
    'products_twentyone_safetyWarning':'Keep away from fire or flame. For external use only. When using this product do not use in or near the eyes. In case of contact, rinse eyes thoroughly with water. Stop use and ask a doctor if irritation or rash appears or lasts. Keep out of reach of children. If swallowed, get medical help or contact a poison control center right away.',


    'products_twentytwo_title':'Hand Sanitizer-50ml- Original',
    'products_twentytwo_desc':'Care plus Hand Sanitizer kills 99.99% of germs instantly without water leaving your hands feeling refreshed. Carry and use whenever, where ever: car, office, picnics, nappy change, camping, travel, sports etc.',
    'products_twentytwo_dir':'Squeeze a small amount of Careplus Hand Sanitizer in your palm then briskly rub hands together until dry. Store Below 30 C.',
    'products_twentytwo_caution':'HIGHLY FLAMMABLE. Keep away from the fire and flame. For external use only. In case of eye contact, flush with water. Discontinue use if skin irritation occurs. Id condition persists, consult a doctor. Keep out of reach of children. If swallowed contact a Poisons Information Center.',
    'products_twentytwo_ingredients':'Acrylates/C10-30 Alkyl Acrylate, Crosspolymer, PEG/PPG-17/16 Copolymer, Alcohol Denat, Aqua, Mono Propylene Glycol, Triethanoiamine, Parfum, Linalool, Hexyl Cinnamai, Hydroxycitronellai, Limonene.',
    'products_twentytwo_safetyWarning':'Keep away from fire or flame. For external use only. When using this product do not use in or near the eyes. In case of contact, rinse eyes thoroughly with water. Stop use and ask a doctor if irritation or rash appears or lasts. Keep out of reach of children. If swallowed, get medical help or contact a poison control center right away.',


    'products_twentythree_title':'Hand Sanitizer-600ml- Herbel',
    'products_twentythree_desc':'Care plus Hand Sanitizer kills 99.99% of germs instantly without water leaving your hands feeling refreshed. Carry and use whenever, where ever: car, office, picnics, nappy change, camping, travel, sports etc.',
    'products_twentythree_dir':'Squeeze a small amount of Careplus Hand Sanitizer in your palm then briskly rub hands together until dry. Store Below 30 C.',
    'products_twentythree_caution':'HIGHLY FLAMMABLE. Keep away from the fire and flame. For external use only. In case of eye contact, flush with water. Discontinue use if skin irritation occurs. Id condition persists, consult a doctor. Keep out of reach of children. If swallowed contact a Poisons Information Center.',
    'products_twentythree_ingredients':'Acrylates/C10-30 Alkyl Acrylate, Crosspolymer, PEG/PPG-17/16 Copolymer, Alcohol Denat, Aqua, Mono Propylene Glycol, Triethanoiamine, Parfum, Linalool, Hexyl Cinnamai, Hydroxycitronellai, Limonene.',
    'products_twentythree_safetyWarning':'Keep away from fire or flame. For external use only. When using this product do not use in or near the eyes. In case of contact, rinse eyes thoroughly with water. Stop use and ask a doctor if irritation or rash appears or lasts. Keep out of reach of children. If swallowed, get medical help or contact a poison control center right away.',



    'products_button':'View More',
    'products_button_more':'CHECK FOR MORE PRODUCTS',


    'clients_title':'CLIENTS',

    'contact_title':'CONTACT',
    'contact_text':'For any inquiries or questions please fill out the following form',
    'contact_form_name':'Name',
    'contact_form_email':'Email',
    'contact_form_phone':'Phone Number',
    'contact_form_message':'Message',
    'contact_form_submit':'SUBMIT',

    'contact_address':'T5-033, SAIF ZONE, Sharjah - UAE',
    'contact_pobox':'P.O.BOX ',
    'contact_follow':'Follow us ',

    'footer_privacy':'Privacy Policy',
    'footer_terms':'Terms and Conditions',
    'footer_design':' Made with',
    'footer_designby':'by',
    'footer_company':'BlackGem',
    'footer_copyright':'All Rights Reserved',

    'otherproducts_title_one':'General Purpose Cleaners & Detergents',
    'otherproducts_title_one_item_one':'Bleach',
    'otherproducts_title_one_item_two':'Furnishings Freshener',
    'otherproducts_title_one_item_three':'Liquid Detergents',
    'otherproducts_title_one_item_four':'Glass and Surface cleaner',
    'otherproducts_title_one_item_five':'Multi-Function Cleaner',
    'otherproducts_title_one_item_six':'Powder Detergent',
    'otherproducts_title_one_item_seven':'Flash (Hydrochloric Acid)',
    'otherproducts_title_one_item_eight':'Harpic',
    
    'otherproducts_title_two':'Floor and Carpet Care Products',
    'otherproducts_title_two_item_one':'Carpet and Curtain Shampoo',
    'otherproducts_title_two_item_two':'Furnishings Freshener',
    'otherproducts_title_two_item_three':'Liquid Detergents',
    'otherproducts_title_two_item_four':'Glass and Surface cleaner',


    'otherproducts_title_three':'Kitchen Care Products',
    'otherproducts_title_three_item_one':'Dish wash',
    'otherproducts_title_three_item_two':'Fruits and Vegetables wash',
    'otherproducts_title_three_item_three':'Oven Cleaner',


    'otherproducts_title_four':'Laundry Care Products',
    'otherproducts_title_four_item_one':'Abaya Shampoo',
    'otherproducts_title_four_item_two':'Fabric Softener',
    'otherproducts_title_four_item_three':'White clothes detergent',


    'otherproducts_title_five':'Disinfectants',
    'otherproducts_title_five_item_one':'Antiseptic disinfectant',
    'otherproducts_title_five_item_two':'Air Freshener',
    'otherproducts_title_five_item_three':'Surface disinfectant',


    'otherproducts_title_six':'Pesonal Care Products',
    'otherproducts_title_six_item_one':'Hand wash',
    'otherproducts_title_six_item_two':'Hand Sanitizer Gel',
    'otherproducts_title_six_item_three':'Hair Shampoo Antidandruff',
    'otherproducts_title_six_item_four':'Hair Conditioner',
    'otherproducts_title_six_item_five':'Hair Shampoo for Saloon',
    'otherproducts_title_six_item_six':'Hair Conditioner for Saloon',
    'otherproducts_title_six_item_seven':'Hair Gel',
    'otherproducts_title_six_item_eight':'Shaving Gel',
    'otherproducts_title_six_item_nine':'Shower Gel',
    

    'otherproducts_title_seven':'Car Care Products',
    'otherproducts_title_seven_item_one':'Car Shampoo',
    'otherproducts_title_seven_item_two':'Tire Polish',

    'privacy_title':'PRIVACY POLICY',
    'privacy_title_one':'General',
    'privacy_title_one_para_one':'D.D.C Factory F.Z.C and its affiliated companies (“D.D.C Factory F.Z.C”) respects the privacy of every individual who visits our sites or responds to our interactive advertisements and other communications. This Privacy Statement outlines the information D.D.C Factory F.Z.C will collect and how we will use that information. This Statement will also instruct you on what to do if you do not want your personal information collected or shared when you visit D.D.C Factory F.Z.C sites or respond to our advertisements and other communications',

    'privacy_title_one_para_two':'We may modify this Privacy Statement and you should therefore periodically visit this page to be sure that you have read and agree with our most current privacy statement.',

    'privacy_title_two':'Personally Identifiable Information',
    'privacy_title_two_para_one':'D.D.C Factory F.Z.C will not collect any personally identifiable information about you (that is, your name, address, telephone number, or email address) unless you provide it to us voluntarily.',
    
    'privacy_title_two_para_two':'When you provide personally identifiable information to us, we may ask if you want to receive future communication from us, such as newsletters, email or other information. You can choose not to receive this communication, if you wish.',

    'privacy_title_two_para_three':'If you do not want your personally identifiable information collected, please do not submit it to us. If you have already submitted this information and would like us to remove it from our records, please visit the "contact us" page on the brand or the corporate web site.',

    'privacy_title_two_para_four':'In addition to providing you with the service or information you request, your personally identifiable information may also be used to help us track the way you use our site and our products. We may also combine the information you provide with information that we collect separately, including information you have provided to other organisations (with consent that it can be shared) as well as public information. All of this information will help us, for example, better design our products, web sites and advertisements. In order to do this, we may also share your information with companies who carry out work for us. These companies have agreed to only use your information in the way we tell them and to have procedures in place to protect the information.',

    'privacy_title_two_para_five':'If we share your information with a company, other than as set out above, we will contact you first and give you the opportunity to remove your information from our database unless you have already given your consent to this.',


    'privacy_title_three':'Children',
    'privacy_title_three_para_one':'D.D.C Factory F.Z.C has no intention of collecting any personally identifiable information (that is, name, address, telephone number, or email address) from individuals under the age of twenty one years. Where appropriate, D.D.C Factory F.Z.C will specifically instruct children not to submit such information. If a child has provided us with personally identifiable information, a parent or guardian of that child should contact us via the "contact us" page on the brand or the corporate website if they would like this information deleted from our records.',
    
    'privacy_title_three_para_two':'Non-Personally Identifiable Information Collected Automatically.',

    'privacy_title_three_para_three':'In some cases, we may collect information about you that is not personally identifiable. Examples of this type of information include the type of Internet Browser you are using, the type of computer operating system you are using, and the domain name of the site from which you linked to our site or advertisement. We use this information for the purposes of better understanding our customers’ interests and to help improve our business and sites.',

    'privacy_title_three_para_four':'Information We May Place Automatically On Your Computer\'s Hard Drive.',

    'privacy_title_three_para_five':'When you view one of our sites or advertisements, we may store some information on your computer. For example, this information may be in the form of a "Cookie" or similar file that will allow us to tailor a site or advertisement to better match your interests and preferences. With most Internet Browsers, you can erase Cookies from your computer hard drive, block all Cookies, or receive a warning before a Cookie is stored. Please refer to your Browser instructions or help screen to learn more about these functions. Please note that removing or blocking Cookies may interfere with your full use of our site.',

    'privacy_title_four':'Whom to Contact',
    'privacy_title_four_para_one':'If you have submitted personally identifiable information through a D.D.C Factory F.Z.C site or interactive advertisement or other communication, and would like that information deleted from our records, please visit the "contact us" page on the brand or the corporate web site.',

    'terms_title':'TERMS & CONDITIONS',
    'terms_title_two':'IMPORTANT LEGAL NOTICE',

    'terms_para_one':'ATTENTION Access to and use of this site and all D.D.C Factory F.Z.C Brands and Websites (hereinafter defined as “Site/ Sites“) are subject to the following terms and conditions and all applicable laws. By viewing this Site, you agree to its terms; if you do not agree to its terms, do not view this Site. Our Sites may contain additional terms and conditions that shall be applicable to the use of those Sites. In the event that there is any conflict between the terms and conditions contained herein and the additional terms on any of our Sites then these terms shall take precedence. D.D.C Factory F.Z.C Brands reserves the right to revise these terms and conditions and/or to terminate without notice access to the Sites at any time. You should check the Sites from time to time to check the then current Legal Notice and other additional terms and conditions, because they are binding on you.',

    'terms_para_two':'You may browse this Site for personal entertainment and information. You may download on one computer or print any material on the Site for your own non- commercial, educational, private or domestic use only. However, you may not distribute, modify, transmit or revise the contents of this Site without written permission of D.D.C Factory F.Z.C and Websites. You may not link to the Site from a third party site without our prior written permission.',

    'terms_para_three':'Unless otherwise stated, the copyright and other intellectual property rights in all material on the Site (including without limitation photographs and graphical images) are owned by D.D.C Factory F.Z.C or its licensors. Neither title nor intellectual property rights are transferred to any third party through the use or access to this Site; rather, all rights, title, and interest in and to all aspects of this Site remain the sole property of D.D.C Factory F.Z.C or its licensors. The D.D.C Factory F.Z.C Sites are protected under copyright and other applicable laws. If you breach any of the terms in this Legal Notice or any Sites, your permission to use the Sites automatically terminates. Any rights not expressly granted herein are reserved.',

    'terms_para_four':'If you wish to formally communicate with D.D.C Factory F.Z.C concerning a legal matter, please contact your localD.D.C Factory F.Z.C Company. Our corporate site at DDCIF.com contains a list of our local companies.',

    'terms_para_five':'The material on the Site is provided “as is “, without any conditions, warranties, representations or other terms of any kind. D.D.C Factory F.Z.C makes no warranties or representations about the accuracy or completeness of the content of this Site or any site(s) "hot-linked" to this Site. D.D.C Factory F.Z.C may make changes to content at any time without notice. The material on the Site may be out of date and D.D.C Factory F.Z.C makes no commitment to update such material. Accordingly, to the maximum extent permitted by law, D.D.C Factory F.Z.C provides you with the Site on the basis that D.D.C Factory F.Z.C excludes all representations, warranties, conditions, and other terms, which, but for this Legal Notice might have effect in relation to the Site.',

    'terms_para_six':'Sites which are designed to provide information about our healthcare products or licensed medicines are not intended to provide medical advice or instructions as to use but only as general information which should not be relied upon by any individual or for any specific purpose. Always consult your doctor or pharmacist for advice on treatment of individual circumstances and needs.',

    'terms_para_seven':'D.D.C Factory F.Z.C (including any of its affiliates and any officers, directors, employees, shareholders or agents of D.D.C Factory F.Z.C and its affiliates) shall not be liable for any direct, indirect, incidental, consequential or punitive damages arising out of: (a) the ability (or inability) to access this Site, (b) the use (or inability to use) any content of this Site, or (c) the content of any site(s) "hot-linked" to this Site. In addition, D.D.C Factory F.Z.C does not warrant that this Site or the server that makes it available are free of viruses or other harmful components; you (and not D.D.C Factory F.Z.C ) assume the entire cost of all necessary servicing, repair, or correction and any award in any related proceedings shall be limited to monetary damage and shall include no injunction or direction to any party other than the direction to pay a monetary amount.',

    'terms_para_eight':'By posting or transmitting to this Site over the Internet you are warranting to D.D.C Factory F.Z.C that the material is and will be treated as, non-confidential and non-proprietary. By transmitting or posting any communication or material to this Site you agree that D.D.C Factory F.Z.C and any of its affiliates may use your communication and/or material for any purpose, including reproduction, transmission, publication, broadcast and posting. D.D.C Factory F.Z.C is under no obligation to respond to messages posted to this Site, nor need D.D.C Factory F.Z.C provide any compensation for any such communication and/or material. Please be aware that applicable national law governs the type of material that can be posted to a website; and you must not contravene any such laws including those that prohibit threatening, libellous, defamatory, obscene, pornographic, indecent or profane material, or post any material that could constitute or encourage conduct that would be considered a criminal offence or in contravention of any other applicable law.',

    'terms_para_nine':'D.D.C Factory F.Z.C is unable to and accepts no responsibility to review communications or materials posted to its Sites. However, D.D.C Factory F.Z.C does reserve the right, using its sole discretion, to remove any communications or materials that it considers unacceptable for whatever reason.',

    'terms_para_ten':'D.D.C Factory F.Z.C only purports to use names, logos or marks appearing in this Site in those territories in which it or its affiliates are entitled to do so, whether by virtue of pending or registered trademarks, licenses, or otherwise. For the avoidance of doubt, D.D.C Factory F.Z.C does not purport to use any name, logo or mark in any territory in which it is not so entitled, and will not supply or offer to supply products and/or services bearing any such name, logo or mark into any such territory. The use or misuse of these trademarks or any other content on this Site, except as provided in these terms and conditions or in the Site content, is strictly prohibited.',

    'terms_para_eleven':'Your use of this Site is governed in all respects by the laws of the United Arab Emirates. Any cause of action or legal claim you may have in respect of this Site must be commenced within 12 months of the cause of action arising.',

    'terms_para_twelve':'These Terms and Conditions are effective as of March 1st, 2020 .',

    'terms_para_thirteen':'D.D.C Factory F.Z.C. All rights reserved.',

    'terms_para_fourteen':'D.D.C Factory F.Z.C',

    'terms_para_fifteen':'P.O. Box 9100',

    'terms_para_sixteen':'SAIF ZONE, T5-033',

    'terms_para_seventeen':'Sharjah,',

    'terms_para_eightteen':'United Arab Emirates',
  },
};
