import React, { Component } from "react";
// import Logo from "../images/navAndFooter/Renewables_transparent Logo_side.png";
import { HashLink as Link } from "react-router-hash-link";
import Logo from "../../media/logo/ddcLogo-removebg-preview.png";
import $ from "jquery";
import { withRouter } from 'react-router-dom';
import translate from "../../i18nProvider/translate";
import { I18nPropvider, LOCALES } from '../../i18nProvider';



export class Nav extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    NavClass: ""
 }


  componentDidMount() {
    // console.log(this.countNum);
    
    $(document).scroll(function () {
      var $nav = $(".navbar-fixed-top");
      $nav.toggleClass('scrolled', $(this).scrollTop() > $nav.height());
    });
    if(this.props.location.pathname !='/'){
      this.setState({
        NavClass:"colored"
      });
    }
      else{
        this.setState({
          NavClass:""
        });
      }
     
   
  }
  componentWillReceiveProps(nextProps) {
    if(nextProps.location.pathname !='/'){
      this.setState({
        NavClass:"colored"
      });
    }
      else{
        this.setState({
          NavClass:""
        });
      }

     // console.log("  componentWillReceiveProps");
       
  }


  render() {
    
    let condition=null;
    if(this.props.changeStyle===LOCALES.ENGLISH){
      condition=(
        <a><p className="changeLanguage" onClick={()=>this.props.handeler(LOCALES.ARABIC)}>العربية</p></a>
      );
    }

      else{
        condition=(
          <a><p className="changeLanguage" onClick={()=>this.props.handeler(LOCALES.ENGLISH)}>English</p></a>
        );
      }
        
      console.log(this.props.changeStyle);

    
   
    return (
      <div>
        <header>
          <nav id="nav" className={"transform navbar-fixed-top "+this.state.NavClass}>
            <div id="flex2">
   
              <div>
                <Link to="/" exact="true">
                  <img id="navLogo" src={Logo} alt="logo" />
                </Link>
              </div>

              <div>
                <ul className="navLinks">
                  <li className="homeLink">
                    <Link to="/">
                      <p className="Linehome">{translate('nav_home')}</p>
                    </Link>{" "}
                  </li>
                  

                  <li className="aboutLink">
                    <Link to="/#about">
                    {" "}
                      <p className="Lineabout">{translate('nav_about')}</p>
                    </Link>
                  </li>

                  <li className="servicesLink">
                    <Link to="/#services">
                      {" "}
                      <p className="Lineservices">{translate('nav_services')}</p>
                    </Link>
                  </li>

                 

                  <li className="projectsLink">
                    <Link to="/#ourProducts">
                      {" "}
                      <p className="Lineprojects">{translate('nav_product')}</p>
                    </Link>
                  </li>

                  <li className="contactLink">
                    <Link to="/#contact">
                      {" "}
                      <p className="Linecontact">{translate('nav_contact')}</p>
                    </Link>
                  </li>
                  
                  <li className="langLink">
                  {/* <select
                  className="custom-select"
                  // value={props.language}
                  onChange={e => this.props.handeler(e.target.value)}
                >
                  <option value={LOCALES.ENGLISH}>English</option>
                  <option value={LOCALES.ARABIC}>العربية</option>
                </select> */}
                 {/* <button onClick={()=>this.props.changeStyle()}>العربية</button> */}
                 
                 {condition}
                
                </li>
                </ul>
              </div>

              {/* nav hanburger menu  */}

              <div id="navMenuIcon">
                <span id="lineTop"></span>
                <span id="lineMiddle"></span>
                <span id="lineBottom"></span>
              </div>
            </div>

            <div id="navDropMenu1" className="transform">
              <ul className="navDropUl">
                <Link to="/">
                  <li className="homeLink">{translate('nav_home_mobile')}</li>
                </Link>{" "}
                <Link to="/#about">
                  <li className="aboutLink">{translate('nav_about_mobile')}</li>
                </Link>
                <Link to="/#services">
                  <li className="servicesLink">{translate('nav_services_mobile')}</li>
                </Link>
                <Link to="/#ourProducts">
                  <li className="projectsLink">{translate('nav_product_mobile')}</li>
                </Link>
                <Link to="/#contact">
                  <li className="contactLink"> {translate('nav_contact_mobile')} </li>
                </Link>
                {condition}
              </ul>
            </div>
          </nav>
        </header>
      </div>
    );
  }
}

 export default withRouter(Nav);
