import translate from "../../i18nProvider/translate";
export default [
  {
    para: translate('terms_para_one')
  },
  {
    para:translate('terms_para_two')
  },
  {
    para:translate('terms_para_three')
  },
  {
    para: translate('terms_para_four')
  },
  {
    para:translate('terms_para_five')
  },
  {
    para: translate('terms_para_six')
  },
  {
    para:translate('terms_para_seven')
  },
  {
    para:translate('terms_para_eight')
  },
  {
    para:translate('terms_para_nine')
  },
  {
    para:translate('terms_para_ten')
  },
  {
    para: translate('terms_para_eleven')
  },
  {
    para: translate('terms_para_twelve')
  },
  {
    para: translate('terms_para_thirteen')
  },
  {
    para: translate('terms_para_fourteen')
  },
  {
    para: translate('terms_para_fifteen')
  },
  {
    para: translate('terms_para_sixteen')
  },
  {
    para: translate('terms_para_seventeen')
  },
  {
    para: translate('terms_para_eightteen')
  }
];
