import React, { Component } from "react";
import Customer from "../../media/Services/customer.png";
import Eco from "../../media/Services/eco.png";
import Chemist from "../../media/Services/materials.png";
import heading from "../../media/arrows/heading.png";
import translate from "../../i18nProvider/translate";

class Services extends Component {
  state = {
    servicesCards: [
      {
        id:1,
        src: Customer,
        title: translate('service_header_first'),
        paragraph: translate('service_desc_first'),
      },
      { 
        id:2,
        src: Eco,
        title: translate('service_header_second'),
        paragraph: translate('service_desc_second')
      },
      {
        id:3,
        src: Chemist,
        title: translate('service_header_third'),
        paragraph: translate('service_desc_third'),
      },
    ],
  };
  render() {
    return (
      <div id="services">
        <div className="services">
          <div className="heading">
            <h2><img src={heading}/>{translate('services_title')}</h2>
            {/* <div className="borderBottom"></div> */}
          </div>

          <div className="wholeServices" data-aos='fade-up'>
            {this.state.servicesCards.map((item) => {
              return (
               
                <div key={item.id}className="singleService" >
                
                  <div className="serviceImg">
                    <img src={item.src} alt="" />
                  </div>
                  <div className="serviceText">
                    <h3>{item.title}</h3>
                    <p>{item.paragraph}</p>
                    <span class="hero__slideContent--right"></span>
                  </div>
                  </div>
               
              );
            })}
          </div>
          {/* <div id="aboutUs"></div> */}
        </div>
      </div>
    );
  }
}

export default Services;
