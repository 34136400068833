import React, { Component } from "react";
import Nav from "./component/nav and footer/Nav";
import Home from "./component/home/Home";
import Terms from "./component/home/Terms";
import Privacy from "./component/home/Privacy";
import Products from "./component/home/Products";
import Footer from "./component/nav and footer/Footer";
import Error404 from "./component/Error404";
import Space from "./component/nav and footer/Space";
import ProductArray from "./component/Product-Details/ProductArray";
import Other from "./component/home/OtherProducts";
import AOS from "aos";
import "aos/dist/aos.css";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import {FormattedMessage} from "react-intl";
import { I18nPropvider, LOCALES } from './i18nProvider';
import translate from "./i18nProvider/translate";
import "./styles/App.css";
import "./styles/MediaQueries.css";

// var rtl="test";



//This is the container component.

export default class App extends Component {
  constructor() {
    super();
    //var styling=this.props.rtlstyle;
    this.state = {
      articles: ProductArray,
      locale:LOCALES.ENGLISH,
      rtl:false
    };
  }
  componentDidMount(){
    
    // if (this.state.rtl === true) {
    //   import('./styles/rtl.css')
    //   .then((style) => {
    //     // console.log(Baz.Baz);
    //   });
    //   //require("./styles/rtl.css");
    //  // require("./styles/MediaQueries.css")

    // }
    // else{
    //   import('./styles/App.css')
    //   .then((style) => {
    //     // console.log(Baz.Baz);
    //   });
    //  // require("./styles/App.css");
    //   //require("./styles/MediaQueries.css")
    // }
    AOS.init({
      duration : 2000,
      once: true
    })
    require("./styles/MediaQueries.css")
  }
 
  langhandler = (lang) => {
    const check=this.state.rtl;
    this.setState({
      locale:lang,
      rtl:!check

    });
   
  }
  

componentDidUpdate(){
  if (this.state.rtl === true) {
    import('./styles/rtl.css')
    .then((style) => {
      // console.log("style.style");
    });
   // require("./styles/rtl.css");
   // require("./styles/MediaQueries.css")
  }
  else{
    // import('./styles/App.css')
    // .then((style) => {
    //   console.log("style.style");
    // });
    window.location.reload(true);

    //require("./styles/App.css")
  }
 //console.log(this.state.rtl)
}
 
 
  
 
  render() {
    return (
      <I18nPropvider locale={this.state.locale}>
      <div className="App">
     
        <Router>
          {/* nav for all pages */}
          <Nav handeler={this.langhandler} changeStyle={this.state.locale}/>

          <Space />
         
          {/* the body would change depending on the route*/}

          <div className="bodyClick">
            <Switch>
              <Route path="/" exact component={Home} />
              <Route path="/termsAndConditions/" exact component={Terms} />
              <Route path="/privacyPolicy/" exact component={Privacy} />
              <Route path="/otherProducts/" exact component={Other} />
              {/* <Route path="/about/" component={About} /> */}
              {/* pass prop from app to Products page */}

              <Route
                path="/products/:id"
                exact
                strict
                render={(routerProps) => {
                  return (
                    <div>
                      {this.state.articles.some((item) => {
                        console.log(
                          "found",
                          routerProps.match.params.id,
                          "id is",
                          item.id
                        );
                        return item.id == routerProps.match.params.id;
                      }) ? (
                        <Products
                          {...routerProps}
                          articles={this.state.articles}
                        />
                      ) : (
                        <Route component={Error404} />
                      )}

                      {console.log(
                        "routes id",
                        routerProps.match.params.id == 1
                      )}

                      {console.log(
                        "some function",
                        this.state.articles.some((item) => {
                          console.log(
                            "found",
                            routerProps.match.params.id,
                            "id is",
                            item.id
                          );
                          return item.id == routerProps.match.params.id;
                        })
                      )}

                      {console.log(
                        "hi",
                        routerProps.match.params.id == this.state.articles.id
                      )}
                    </div>
                  );
                }}
              />

              {/* <Route path="/contact/" component={Contact} /> */}

              <Route component={Error404} />
            </Switch>

            {/* footer for all pages */}
           
          {/* <button onClick={() => this.setState({locale:LOCALES.ENGLISH})}>English</button>
          <button onClick={() => this.setState({locale:LOCALES.FRENCH})}>French</button>
          <button onClick={() => this.setState({locale:LOCALES.GERMAN})}>German</button> */}

        {/* <button onClick={()=>this.langhandler(LOCALES.FRENCH)}>FRENCH</button> */}
        {/* <button onClick={this.changeStyle}>العربية</button> */}


            <Footer />
          </div>
        </Router>
      </div>
      </I18nPropvider>
    );
  }
}
