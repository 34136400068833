import translate from "../../i18nProvider/translate";
export default [
  {
    para:translate('privacy_title_one')
  },
  {
    para:translate('privacy_title_one_para_one')
  },
  {
    para: translate('privacy_title_one_para_two')
  },
  {
    para: translate('privacy_title_two')
  },
  {
    para: translate('privacy_title_two_para_one')
  },
  {
    para: translate('privacy_title_two_para_two')
  },
  {
    para: translate('privacy_title_two_para_three')
  },
  {
    para:translate('privacy_title_two_para_four')
  },
  {
    para:translate('privacy_title_two_para_five')
  },
  {
    para:translate('privacy_title_three')
  },
  {
    para:translate('privacy_title_three_para_one')
  },
  {
    para: translate('privacy_title_three_para_two')
  },
  {
    para: translate('privacy_title_three_para_three')
  },
  {
    para: translate('privacy_title_three_para_four')
  },
  {
    para: translate('privacy_title_three_para_five')
  },
  {
    para: translate('privacy_title_four')
  },
  {
    para: translate('privacy_title_four_para_one')
  }
];
