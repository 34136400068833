import React, { Component } from "react";
import heading from "../../media/arrows/heading.png"
import aboutImg from "../../media/arrows/cleaning.jpg"
import circleImg from "../../media/arrows/circle.png"
import translate from "../../i18nProvider/translate";
// import WOW from "wowjs";
//
export default class AboutUs extends Component {
  // componentDidMount() {
  //   const wow = new WOW.WOW({
  //     boxClass: 'wow',
  //     animateClass: 'animated',
  //     offset: 0,
  //     mobile: true,
  //     live: true
  //   });
  //   wow.init();
  // }
  
  render() {
    return (
      <div id="about" className="homeContactUs about " data-aos='fade-up' >
        <div className="aboutContent">
        <div className="homecontactDiv">
          {/* <ContactUS page="contactHome" /> */}
          <div className="headingLeft">
            <h2><img src={heading}/>{translate('about_title')}</h2>
            {/* <div className="borderBottom"></div> */}
          </div>
          <div className="aboutText">
            <p>
               {translate('about_pagraph_one')}
              </p>
              <p>
              {translate('about_pagraph_secoend')}
              </p>

              <p>
              {translate('about_pagraph_three')}
            </p>
          </div>
        </div>

        <div className="homeContacUsImg">
          {/* Changing the home page image in contact us section */}
          {/* <img src="https://i0.wp.com/norcheminc.com/img/norchem-distributors-products.jpg"/> */}
          <img src={aboutImg}/>
          <span class="about-right"></span>
        </div>
        </div>
        <img src={circleImg} className="circleImg"/>
      </div>
    );
  }
}
