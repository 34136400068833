import React, { Component } from 'react';



export class Space extends Component {
    render() {
        return (
            <div id='space'></div>

        )
    }
}

export default Space;