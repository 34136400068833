import React, { Component } from "react";
import heading from "../../media/arrows/heading.png";
import mail from "../../media/footerIcons/mail.png"
import phone from "../../media/footerIcons/phone.png"
import mailbox from "../../media/footerIcons/mailbox.png"
import map from "../../media/footerIcons/marker.png"
import facebook from "../../media/SocialMedia/facebook.png";
import linked from "../../media/SocialMedia/linkedin.png";
import translate from "../../i18nProvider/translate";
import Input from '../../input';


export default class Contact extends Component {
  //pass in page name as props, either home or contact .. and these two have different classes (style is slightly different)
  // constructor(props) {
  //   super();
  //   this.state = {
  //     page: props.page
  //   };
  // }
  render() {
    return (
      <div id='contact' className="contact">
        <div className="heading">
        <h2><img src={heading}/>{translate('contact_title')}</h2>
          {/* <div className="borderBottom"></div> */}
        </div>
        <div className="wholeContact">
          {/* <div className="map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d28848.90707280686!2d55.47768893306522!3d25.333976024677998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ef5f5e36cfa2cc7%3A0x11f502f4edb88468!2sSaif%20Zone%20-%20Sharjah%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2s!4v1585507415126!5m2!1sen!2s"
              width="1000"
              height="450"
              frameborder="0"
              style={{ border: 0 }}
              allowfullscreen=""
              aria-hidden="false"
              tabindex="0"
            ></iframe>
          </div> */}

          <div className="thisContactSection">
            <div className="contactInfo"  data-aos='fade-left'>
              {/* <div className="contactText">
                <h2>Inquiries</h2>
                <p>
                  For any inquiries or questions please call us:
                  <br />
                  +971 6 533 7373 or fill out the following form
                </p>
              </div> */}
                 <div className="contactForm" >
               
               <p>{translate('contact_text')}</p>
                 <form action="" method="post" action="https://formspree.io/mpzywrnd">
                  
                   {/* <input name="name" type="text" placeholder={translate('contact_form_name')} required />
                   
                   <input type="email" name="email" placeholder={translate('contact_form_email')} required />
                  
                   <input
                     name="name"
                     type="phone"
                     placeholder={translate('contact_form_phone')}
                     required
                   /> */}

            <Input />
          
                   {/* <p>Enter your Message</p> */}
                   {/* <textarea
                     name="message"
                     id=""
                     cols="30"
                     rows="10"
                     placeholder={translate('contact_form_message')}
                     required
                   ></textarea> */}
                   <button>{translate('contact_form_submit')}</button>
                 </form>
               </div>


             
             
            </div>

            <div className="contactInfo2" data-aos='fade-left'>
            {/* <div className="map"  data-aos='fade-right'>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d28848.90707280686!2d55.47768893306522!3d25.333976024677998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ef5f5e36cfa2cc7%3A0x11f502f4edb88468!2sSaif%20Zone%20-%20Sharjah%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2s!4v1585507415126!5m2!1sen!2s"
              width="600"
              height="450"
              frameborder="0"
              style={{ border: 0 }}
              allowfullscreen=""
              aria-hidden="false"
              tabindex="0"
            ></iframe>
          </div> */}
             <ul className="footerLinks">
          <div className="footerInner1">
            <a href="mailto:info@ddcif.com">
              <div class="Icon mail">
                <img src={mail}/>
              </div>
              <li>
                {/* <Link to="/">Home</Link> */}
                info@ddcif.com
              </li>
            </a>

            <a>
              <div className="Icon phone">
              <img src={phone}/>
              </div>
              <li>
                {/* <Link to="/">Home</Link> */}
                +971 6 533 7373
              </li>
            </a>
          </div>

          <div className="footerInner2">
            <a>
              <div className="Icon location">
              <img src={map}/>
              </div>
              <li>
                {/* <Link to="/">Home</Link> */}
                {translate('contact_address')}
              </li>
            </a>
            <a>
              <div className="Icon mailBox">
              <img src={mailbox}/>
              </div>
              <li>
                {/* <Link to="/">Home</Link> */}
                {translate('contact_pobox')}: 9100
              </li>
            </a>
          </div>
        </ul>
               <div className="follow">
                <p>{translate('contact_follow')}</p>
                <ul>
                  <li><a href="https://www.facebook.com/Ilovecareplus" target="_blank">
                  <img
                    className=""
                    src={facebook}
                    alt="facebook_icon"
                  />
                    </a></li>

                   

                    <li><a href="https://www.linkedin.com/company/ddc-intrnational-factory/" target="_blank">
                  <img
                    className=""
                    src={linked}
                    alt="twitter_icon"
                  />
                    </a></li>
                </ul>
              </div>

             
              {/* <div className="contactText">
                <h2>Head Office</h2>
                <p>T5-033, SAIF Zone, Sharjah – U.A.E</p>
                <p>
                  info@ddcif.com Tel: +971 6 533 7373 <br /> P.O. BOX: 9100{" "}
                </p>
              </div> */}
              {/* <div className="contactText">
                <h2>Employment</h2>
                <p>
                  To apply for a job with D.D.C International Factory , please
                  send a cover letter together with your C.V. to: info@ddcif.com
                </p>
              </div> */}


              {/* <div className="contactText">
                <div className="borderBottom"></div>{" "}
                <div className="quote">
                  <h2>Get a quote : 123456789 </h2>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div className="divider"></div>
      </div>
     
    );
  }
}
