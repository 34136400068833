import React from "react";
import { Link } from "react-router-dom";
import ProductArray from "./ProductArray";
import heading from "../../media/arrows/heading.png"
import $ from "jquery";
import translate from "../../i18nProvider/translate";


class LatestStories extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      articles: ProductArray,
      displayNum: "8",
      count: 2,
      by: "by ",
      source: "Source: ",
    };
  }

  showMore = () => {
    this.setState({ displayNum: this.state.count * 8 });

    // if (this.state.displayNum >= this.state.articles.length) {
    //   // alert("There are no more products to display!");
    //   $(".morebtn").each(function () {
    //     $(this).replaceWith(
    //       $(
    //         "<a href='/otherProducts/'><button class='morebtn'>" +
    //           "CHECK FOR MORE PRODUCTS" +
    //           "</button></a>"
             
    //       )
    //     );
    //   });
     
    // }
    this.setState({ count: this.state.count + 1 });
    console.log(this.state.count);
    console.log(this.state.displayNum);
  };

  RenderArticles = () => {
    return (
      
      <div className="lastestStory"  >
        {this.state.articles
          .filter((story) => story.id <= this.state.displayNum)
          .map((story) => {
            return (
              <Link
                className="storyLink"
                onClick={this.props.handleId}
                key={story.id}
                to={`/products/${story.id}`}
                data-aos='zoom-in'
              >
                {/* <div> */}
                  <div className="story" >
                    <div
                      className="storyimg"
                      // style={{
                      //   backgroundImage: `url(${
                      //     this.state.articles[story.id - 1].img
                      //   })`,
                      // }}
                     
                    >
                       <img src={this.state.articles[story.id - 1].img} />
                         
                    </div>
                    <h3>{this.state.articles[story.id - 1].title}</h3>
                    {/* <h2>
                      {this.state.by}
                      {this.state.articles[story.id - 1].by}
                    </h2>
                    <h2>
                      {this.state.source}
                      {this.state.articles[story.id - 1].source}
                    </h2> */}
                  </div>
                {/* </div> */}
              </Link>
            );
          })}
      </div>
    );
  };
  
  componentDidUpdate(){

   

      if (this.state.displayNum >= this.state.articles.length) {
      $(".morebtn").each(function () {
        var test=translate('products_button_more');
        // $(this).replaceWith(
        //   $(
        //     "<a href='/otherProducts/'><button class='morebtn checkMore'>" +
        //     translate('products_button_more')+
        //       "</button></a>"
             
        //   )
        // );
        $(this).replaceWith($(".checkMore"))
        $(".checkMore").css("display","inline-block")
        //console.log($(".morebtn"));
      });
      
    }
    //console.log("CHECK FOR MORE PRODUCTS");
     
  }

  render() {
    return (
      
      <div className="products_wraper">
      <div className="latestStorySection">
        <div className="latestStorieshead1">
          <div className="heading">
            <h2><img src={heading}/>{translate('products_title')}</h2>
            {/* <div className="borderBottom"></div> */}
          </div>

          <div className="latestStories">
            <this.RenderArticles />
          </div>

          <button className="morebtn" onClick={this.showMore}>
          {translate('products_button')}
          </button>
          <Link to='/otherProducts/'>
            <li class='morebtn checkMore'>
            {translate('products_button_more')}
              </li></Link>
        </div>
        {/* <Route path={`/:storyID`} render={routerProps => <ArticleShow id={this.state.index} />} /> */}
      </div>
      </div>
    );
  }
}

export default LatestStories;
