import { LOCALES } from '../constants';

export default {
  [LOCALES.ARABIC]: {
    'nav_home':'الرئيسية',
    'nav_about':'عنا',
    'nav_services':'خدماتنا',
    'nav_product':'منتجاتنا',
    'nav_contact':'تواصل معنا',

    'nav_home_mobile':'الرئيسية',
    'nav_about_mobile':'عنا',
    'nav_services_mobile':'خدماتنا',
    'nav_product_mobile':'منتجاتنا',
    'nav_contact_mobile':'تواصل معنا',

    'main_heading': '  المصنع العالمي',
    'main_heading_paragraph': 'للكيماويات الصناعية والمنظفات',
    'main_heading_button': 'منتجاتنا',

    'about_title':'عنا',

    'about_pagraph_one':'إن مصنع D.D.C العالمي F.Z.C ، هي شركة مصنعة للكيماويات الصناعية والمنظفات، وتشارك في كل من النشاطات الصناعية والتجارة. نحن أكبر شركة مصنعة لمنتجات النظافة العامة في الإمارات العربية المتحدة.',

    'about_pagraph_secoend':'يتم تصنيع منتجات عالية الجودة في منشأتنا في منطقة سيف الشارقة ويتم تسليمها إلى السوق المحلي لدولة الإمارات العربية المتحدة بالإضافة إلى تصديرها إلى جميع أنحاء العالم ملبية احتياجات مختلف الصناعات في دول مجلس التعاون الخليجي وأفريقيا.',
   
    'about_pagraph_three':'مصنع D.D.C International F.Z.C معترف به كواحد من أبرز الشركات المصنعة لمواد التنظيف الكيميائية في الإمارات العربية المتحدة | دول مجلس التعاون الخليجي | الشرق الأوسط ',


    'services_title':'خدماتنا',
    'service_header_first':'خدمة العملاء',
    'service_desc_first':'نقوم بإعطاء الأولوية المطلقة لعملائنا وبالتالي نسعى لتقديم خدمة على أعلى مستوى لنيل رضاهم.',

    'service_header_second':'صديقة للبيئة',
    'service_desc_second':'سعى دائماً لتوفير منتجات ذات جودة عالية بحيث نركز على الكيف وليس الكم. كوننا حريصين على سلامة البيئة، فإن منتجاتنا تتوافق مع سلامة البيئة بالإضافة إلى زيادة نسبة العملاء الواعيين بالحفاظ على البيئة.',

    'service_header_third':'أحدث متطلبات السوق',
    'service_desc_third':'إن العاملين معنا من المتخصصين ذوي الكفاءات العالية والخبراء ذوي المؤهلات الرفيعة هم جزء لا يتجزأ من الشركة، والذي من خلالهم يمكننا مواكبة أحدث متطلبات السوق.',


    'achivement_title':'المنتجات المصنعة في اليوم الواحد',
    'achivement_title_first':' منتجات العناية المنزلية',
    'achivement_title_seconed':'منتجات العناية الشخصية',
    'achivement_title_third':'منظفات السيارات',
    'achivement_unit':'ميجا طن',


    'products_title':'منتجاتنا',

    'products_description':'الوصف:',
    'products_directions':'طريقة الإستخدام :',
    'products_caution':'تحذير :',
    'products_ingredients':'المكونات:',
    'products_safetyWarning':'تحذيرات السلامة :',

    'products_next':'التالي',
    'products_previous':'السابق',
    'products_follow':'تابعونا على :',
    'products_more':'المزيد من المنتجات',

    'products_one_title':'مطهر ومعقم 500 مل',
    'products_one_desc':'سائل كير بلس مطهر ومعقم',
    'products_one_dir':'قم بإستخدامة مركز للمراحيض والأحواض والمصارف. ومخفف للاستخدام الطبي والعناية الشخصية والغسيل',
    'products_one_caution':'على الرغم من كونه مطهر لطيف ، لا  تستخدمة للأطفال إلا بإرشادات طبية.',
    'products_one_ingredients':'الكلوروكسيلينول ، زيت الصنوبر ، كحول الآيزوبروبيل ، زيت الخروع ، الكراميل ، الماء منزوع الأيونات',
    'products_one_safetyWarning':'فضل استخدامة في 30 شهرًا الأولى من تاريخ الإنتاج الظاهر على العبوة. للاستخدام الخارجي فقط قم بتخزينه في مكان بارد وجاف بدرجة حرارة لا تقل عن 30 درجة مئوية. قم بإبقائة بعيدا عن متناول الأطفال. غير صالح للأكل ، في حال ظهور إنتفاخات في الجلد ، ينصح بزيارة الطبيب فوراً.',




    'products_two_title':'مطهر ومعقم 5 لتر',
    'products_two_desc':'سائل كير بلس مطهر ومعقم',
    'products_two_dir':'قم بإستخدامة مركز للمراحيض والأحواض والمصارف. ومخفف للاستخدام الطبي والعناية الشخصية والغسيل',
    'products_two_caution':'على الرغم من كونه مطهر لطيف ، لا تستخدمة للأطفال إلا بإرشادات طبية.',
    'products_two_ingredients':'الكلوروكسيلينول ، زيت الصنوبر ، كحول الآيزوبروبيل ، زيت الخروع ، الكراميل ، الماء منزوع الأيونات',
    'products_two_safetyWarning':'فضل استخدامة في 30 شهرًا الأولى من تاريخ الإنتاج الظاهر على العبوة. للاستخدام الخارجي فقط قم بتخزينه في مكان بارد وجاف بدرجة حرارة لا تقل عن 30 درجة مئوية. قم بإبقائة بعيدا عن متناول الأطفال. غير صالح للأكل ، في حال ظهور إنتفاخات في الجلد ، ينصح بزيارة الطبيب فوراً.',

     

    'products_three_title':'كلوروماكس 1 لتر',
    'products_three_desc':'يبيض ويزيل البقع وينظف ويطهر ',
    'products_three_dir':'مناسب للاستخدام في: المرحاض ، الحمام ، المطبخ ، الأرضيات ، البلاط ، ألعاب الأطفال البلاستيكية. قم بإستخدامة على الأسطح الصلبة غير المسامية. يحتوي على تركيبة مضادة للاصفرار.',
    'products_three_caution':'يجب تخفيف المنتج بالماء وفقًا للتعليمات الموجودة على العبوة. لا تستخدم هذا المنتج دون تخفيفه بالماء لتنظيف الأسطح. من المستحسن أيضًا ارتداء قفازات مطاطية عند التنظيف.',
    'products_three_ingredients':'هيبوكلوريت الصوديوم 6.15٪ ، مكونات أخرى 93.85٪',
    'products_three_safetyWarning':'يجب إبقاء المنتج بعيدا عن متناول الأطفال. لا تقم بخلطة مع منظفات المرحاض أو مع المنتجات التي تحتوي على الأحماض أو الأمونيا. قد تطلق غازات خطرة ( كلور). في حال ملامسة المنتج للعينين. قم بغسل العينين بالماء الجاري لمدة 15 دقيقة. إذا استمر الالتهاب ، اتصل بالطبيب.',
    




    'products_four_title':'كلوروماكس 1 قالون',
    'products_four_desc':'يبيض ويزيل البقع وينظف ويطهر',
    'products_four_dir':'مناسب للاستخدام في: المرحاض ، الحمام ، المطبخ ، الأرضيات ، البلاط ، ألعاب الأطفال البلاستيكية. قم بإستخدامة على الأسطح الصلبة غير المسامية. يحتوي على تركيبة مضادة للاصفرار.',
    'products_four_caution':'يجب تخفيف المنتج بالماء وفقًا للتعليمات الموجودة على العبوة. لا تستخدم هذا المنتج دون تخفيفه بالماء لتنظيف الأسطح. من المستحسن أيضًا ارتداء قفازات مطاطية عند التنظيف.',
    'products_four_ingredients':'هيبوكلوريت الصوديوم 6.15٪ ، مكونات أخرى 93.85٪',
    'products_four_safetyWarning':'يجب إبقاء المنتج بعيدا عن متناول الأطفال. لا تقم بخلطة مع منظفات المرحاض أو مع المنتجات التي تحتوي على الأحماض أو الأمونيا. قد تطلق غازات خطرة ( كلور). في حال ملامسة المنتج للعينين. قم بغسل العينين بالماء الجاري لمدة 15 دقيقة. إذا استمر الالتهاب ، اتصل بالطبيب.',


    'products_five_title':'فلاش 1 لتر- أصلي',
    'products_five_desc':'كير بلس فلاش ، منظف مرحاض 1 لتر. تنظيف شامل للتواليت. يزيل البقع. - يقتل 99.9٪ من الجراثيم.',
    'products_five_dir':'اضغط على جانبي الجزء العلوي من المنتج وقم بفكة بحركات دائرية لإزالة الغطاء. قم بالضغط على الوعاء و وصب المنتج حول الجزء الداخلي من المرحاض. للحصول على أفضل النتائج ، اتركة لمدة 30 دقيقة ثم نظف بالفرشاة و واضغط شطاف الماء.',
    'products_five_caution':'ننصحك بارتداء قفازات أثناء تنظيف كرسي المرحاض.',
    'products_five_ingredients':'لكل 100 جرام من السائل: 6.13 جرام من حمض الهيدروكلوريك ، أقل من 5٪ من الفاعل بالسطح غير الأيوني ، مطهر ، ملون. يحتوي على أقل من 5 ٪ من المواد الخافظة للتوتر السطحي ، عطر.',
    'products_five_safetyWarning':'خطر يحتوي على حمض الهيدروكلوريك ، يسبب حروقًا جلدية شديدة وتلفًا للعين. لا تقم بتنفس الأبخرة الصاعدة. أبقة بعيدا عن متناول الأطفال. في حال أبتلاعة ، اشطف الفم بالماء. لا تقم بالتحريض على القيء. اتصل فورًا بمركز مكافحة السموم أو الطبيب.',




    'products_six_title':'فلاش 1 لتر - عطر الورد',
    'products_six_desc':'كير بلس فلاش ، منظف مرحاض 1 لتر. تنظيف شامل للتواليت. يزيل البقع. - يقتل 99.9٪ من الجراثيم.',
    'products_six_dir':'ضغط على جانبي الجزء العلوي من المنتج وقم بفكة بحركات دائرية لإزالة الغطاء. قم بالضغط على الوعاء و وصب المنتج حول الجزء الداخلي من المرحاض. للحصول على أفضل النتائج ، اتركة لمدة 30 دقيقة ثم نظف بالفرشاة و واضغط شطاف الماء.',
    'products_six_caution':'ننصحك بارتداء قفازات أثناء تنظيف كرسي المرحاض.',
    'products_six_ingredients':'لكل 100 جرام من السائل: 6.13 جرام من حمض الهيدروكلوريك ، أقل من 5٪ من الفاعل بالسطح غير الأيوني ، مطهر ، ملون. يحتوي على أقل من 5 ٪ من المواد الخافظة للتوتر السطحي ، عطر.    ',
    'products_six_safetyWarning':'خطر يحتوي على حمض الهيدروكلوريك ، يسبب حروقًا جلدية شديدة وتلفًا للعين. لا تقم بتنفس الأبخرة الصاعدة. أبقة بعيدا عن متناول الأطفال. في حال أبتلاعة ، اشطف الفم بالماء. لا تقم بالتحريض على القيء. اتصل فورًا بمركز مكافحة السموم أو الطبيب.',


    'products_seven_title':'سائل غسيل الصحون - برتقال',
    'products_seven_desc':'كير بلس ، سائل غسيل الأطباق مضاد للبكتيريا ، 1 لتر ، برائحة البرتقال',
    'products_seven_dir':'أضف القليل من سائل غسيل الصحون إلى وعاء صغير من الماء أو مباشرة على الإسفنجة وابدأ الغسيل للحصول على أطباق نظيفة ولامعة.',
    'products_seven_caution':'أبقة بعيداً عن أشعة الشمس المباشرة',
    'products_seven_ingredients':'12-30٪ لابسا، صوديوم ، هيدروكسيد ، صوديوم لوريل إيثر سلفات ، خافض للتوتر غير الأيوني ، بيتاين بروبيل كوكاميد ، جليسرين ، بنزيسوثيازولينون ، ميثيل كلورويسوثيازولينون ، ميثيلسوثيازولينون ، عامل مضاد للجراثيم ، عطر ، صبغ ، ليمونين ، لينالول ، سيترال',
    'products_seven_safetyWarning':'يفضل استخدامة في 30 شهرًا الأولى من تاريخ الإنتاج الظاهر على العبوة. للاستخدام الخارجي فقط  يجب تخزينه في مكان بارد وجاف لا يقل عن 30 درجة مئوية. يجب إبقائة بعيدا عن متناول الأطفال. غير صالح للأكل ، في حال حدوث تورم في الجلد ، ينصح بزيارة الطبيب على الفور.',


    'products_eight_title':'سائل غسيل الصحون - ليمون',
    'products_eight_desc':'كير بلس ، سائل غسيل الأطباق مضاد للبكتيريا ، 1 لتر ، برائحة الليمون',
    'products_eight_dir':'أضف القليل من سائل غسيل الصحون إلى وعاء صغير من الماء أو مباشرة على الإسفنجة وابدأ الغسيل للحصول على أطباق نظيفة ولامعة.',
    'products_eight_caution':'أبقة بعيداً عن أشعة الشمس المباشرة',
    'products_eight_ingredients':'12-30٪ لابسا، صوديوم ، هيدروكسيد ، صوديوم لوريل إيثر سلفات ، خافض للتوتر غير الأيوني ، بيتاين بروبيل كوكاميد ، جليسرين ، بنزيسوثيازولينون ، ميثيل كلورويسوثيازولينون ، ميثيلسوثيازولينون ، عامل مضاد للجراثيم ، عطر ، صبغ ، ليمونين ، لينالول ، سيترال',
    'products_eight_safetyWarning':'يفضل استخدامة في 30 شهرًا الأولى من تاريخ الإنتاج الظاهر على العبوة. للاستخدام الخارجي فقط  يجب تخزينه في مكان بارد وجاف لا يقل عن 30 درجة مئوية. يجب إبقائة بعيدا عن متناول الأطفال. غير صالح للأكل ، في حال حدوث تورم في الجلد ، ينصح بزيارة الطبيب على الفور.',


    'products_nine_title':'معقم لليدين 500 مل - أصلي',
    'products_nine_desc':'ن معقم اليدين كير بلس يقتل 99.99٪ من الجراثيم على الفور دون الحاجة إلى ماء للتمتع بشعور الإنتعاش في يديك . احملها واستخدمها في أي وقت وأي مكان: السيارة ، المكتب ، النزهات ، تغيير الحفاضات ، التخييم ، السفر ، الرياضة إلخ.',
    'products_nine_dir':'ضغط على كمية صغيرة من معقم اليدين كير بلس في راحة يدك ثم افرك يديك معًا حتى يجف. يتم تخزينة في درجة حرارة أقل من 30 درجة مئوية.',
    'products_nine_caution':'قابل للاشتعال بقوة.  يجب إبقائة بعيداً عن النار واللهب. للاستخدام الخارجي فقط. في حالة ملامسة العين ، اغسلهما بالماء. توقف عن الاستخدام في حالة حدوث تهيج للجلد. إذا استمر التهيج ، استشر الطبيب. يحفظ بعيدا عن متناول الأطفال. في حال إبتلاع المنتج ، اتصل بمركز معلومات السموم.',
    'products_nine_ingredients':'كريلات / C10-30 ألكيل أكريلات ، كروس بوليمر ، البولي ايثيلين جلايكول و بوليبروبيلين جليكول 17/16  كوبوليمر ، كحول دينات ، أكوا ، مونو بروبيلين جلايكول ، ثلاثي إيثامين أمين ، عطر ، لينالول ، هيكسيل سيناماي ، هيدروكسي سيترونيلاي ، ليمونين  ',
    'products_nine_safetyWarning':'يجب الابتعاد عن النار أو اللهب. للاستخدام الخارجي فقط. عند استخدام هذا المنتج لا تستخدم في أو بالقرب من العين. في حالة ملامسة العين ، اغسل العينين جيدًا بالماء. توقف عن الاستخدام في حال ظهور تهيج أو طفح جلدي أو استمرارة و أستشر الطبيب. يحفظ بعيدا عن متناول الأطفال. إذا ابتلع ، أطلب المساعدة الطبية أو اتصل بمركز مكافحة السموم على الفور.',


    'products_ten_title':'معقم لليدين 500 مل - بالأعشاب',
    'products_ten_desc':'ن معقم اليدين كير بلس يقتل 99.99٪ من الجراثيم على الفور دون الحاجة إلى ماء للتمتع بشعور الإنتعاش في يديك . احملها واستخدمها في أي وقت وأي مكان: السيارة ، المكتب ، النزهات ، تغيير الحفاضات ، التخييم ، السفر ، الرياضة إلخ.',
    'products_ten_dir':'اضغط على كمية صغيرة من معقم اليدين كير بلس في راحة يدك ثم افرك يديك معًا حتى يجف. يتم تخزينة في درجة حرارة أقل من 30 درجة مئوية.',
    'products_ten_caution':'قابل للاشتعال بقوة. يجب إبقائة بعيداً عن النار واللهب. للاستخدام الخارجي فقط. في حالة ملامسة العين ، اغسلهما بالماء. توقف عن الاستخدام في حالة حدوث تهيج للجلد. إذا استمر التهيج ، استشر الطبيب. يحفظ بعيدا عن متناول الأطفال. في حال إبتلاع المنتج ، اتصل بمركز معلومات السموم.  ',
    'products_ten_ingredients':'أكريلات / C10-30 ألكيل أكريلات ، كروس بوليمر ، البولي ايثيلين جلايكول و بوليبروبيلين جليكول 17/16 كوبوليمر ، كحول دينات ، أكوا ، مونو بروبيلين جلايكول ، ثلاثي إيثامين أمين ، عطر ، لينالول ، هيكسيل سيناماي ، هيدروكسي سيترونيلاي ، ليمونين.',
    'products_ten_safetyWarning':'جب الابتعاد عن النار أو اللهب. للاستخدام الخارجي فقط. عند استخدام هذا المنتج لا تستخدم في أو بالقرب من العين. في حالة ملامسة العين ، اغسل العينين جيدًا بالماء. توقف عن الاستخدام في حال ظهور تهيج أو طفح جلدي أو استمرارة و أستشر الطبيب. يحفظ بعيدا عن متناول الأطفال. إذا ابتلع ، أطلب المساعدة الطبية أو اتصل بمركز مكافحة السموم على الفور.',


    'products_eleven_title':'معقم لليدين 60 مل - أصلي',
    'products_eleven_desc':'ن معقم اليدين كير بلس يقتل 99.99٪ من الجراثيم على الفور دون الحاجة إلى ماء للتمتع بشعور الإنتعاش في يديك . احملها واستخدمها في أي وقت وأي مكان: السيارة ، المكتب ، النزهات ، تغيير الحفاضات ، التخييم ، السفر ، الرياضة إلخ.',
    'products_eleven_dir':'اضغط على كمية صغيرة من معقم اليدين كير بلس في راحة يدك ثم افرك يديك معًا حتى يجف. يتم تخزينة في درجة حرارة أقل من 30 درجة مئوية.',
    'products_eleven_caution':'قابل للاشتعال بقوة. يجب إبقائة بعيداً عن النار واللهب. للاستخدام الخارجي فقط. في حالة ملامسة العين ، اغسلهما بالماء. توقف عن الاستخدام في حالة حدوث تهيج للجلد. إذا استمر التهيج ، استشر الطبيب. يحفظ بعيدا عن متناول الأطفال. في حال إبتلاع المنتج ، اتصل بمركز معلومات السموم. ',
    'products_eleven_ingredients':'كريلات / C10-30 ألكيل أكريلات ، كروس بوليمر ، البولي ايثيلين جلايكول و بوليبروبيلين جليكول 17/16 كوبوليمر ، كحول دينات ، أكوا ، مونو بروبيلين جلايكول ، ثلاثي إيثامين أمين ، عطر ، لينالول ، هيكسيل سيناماي ، هيدروكسي سيترونيلاي ، ليمونين.',
    'products_eleven_safetyWarning':'يجب الابتعاد عن النار أو اللهب. للاستخدام الخارجي فقط. عند استخدام هذا المنتج لا تستخدم في أو بالقرب من العين. في حالة ملامسة العين ، اغسل العينين جيدًا بالماء. توقف عن الاستخدام في حال ظهور تهيج أو طفح جلدي أو استمرارة و أستشر الطبيب. يحفظ بعيدا عن متناول الأطفال. إذا ابتلع ، أطلب المساعدة الطبية أو اتصل بمركز مكافحة السموم على الفور.',


    'products_twelve_title':'منظف زجاج 650 مل',
    'products_twelve_desc':'يوفر كير بلس منظفًا للزجاج و الأسطح مع نظام ثلاثي قوي للمعان: 1- يزيل البقايا الدهنية 2-يزيل كل الأوساخ 3- يترك الزجاج و الأسطح لامعة و متألقة بدون أثر للخطوط.',
    'products_twelve_dir':'أماكن الاستخدام يناسب: الأسطح الملساء واللامعة ، أسطح المنضدات ، الطاولات الزجاجية ، الكروم ، الأجهزة ، البلاط ، البلاستيك.',
    'products_twelve_caution':'غير مناسب : الأسطح المعدنية والنحاسية ، الرخام  المصقول والجص و الإسمنت ، الأسطح الخشبية ، الأثاث الشمعي.',
    'products_twelve_ingredients':'4.0٪ كحول الأيزوبروبيل ، 1٪ إيثيلين جليكول مونوبوتيل إيثر ، 0.1٪ كبريتات لوريل الصوديوم (خافض للتوتر السطحي) ، الكالسيوم (ca) 0.01٪ ، بيروفوسفات رباعي الصوديوم (منعم الماء) ، 0.05٪ من 28٪ أمونيا ، 1٪ من محلول صبغ و 0.01٪ عطر.    .',
    'products_twelve_safetyWarning':'يفضل استخدامة في 30 شهرًا الأولى من تاريخ الإنتاج الظاهر على العبوة. للاستخدام الخارجي فقط يجب تخزينه في مكان بارد وجاف لا يقل عن 30 درجة مئوية. يجب إبقائة بعيدا عن متناول الأطفال. غير صالح للأكل ، في حال حدوث تورم في الجلد ، ينصح بزيارة الطبيب على الفور.',



    'products_thirteen_title':'منظف الزجاج 5 لتر',
    'products_thirteen_desc':'وفر كير بلس منظفًا للزجاج و الأسطح مع نظام ثلاثي قوي للمعان: 1- يزيل البقايا الدهنية 2-يزيل كل الأوساخ 3- يترك الزجاج و الأسطح لامعة و متألقة بدون أثر للخطوط.',
    'products_thirteen_dir':'ماكن الاستخدام يناسب: الأسطح الملساء واللامعة ، أسطح المنضدات ، الطاولات الزجاجية ، الكروم ، الأجهزة ، البلاط ، البلاستيك.',
    'products_thirteen_caution':'غير مناسب : الأسطح المعدنية والنحاسية ، الرخام  المصقول والجص و الإسمنت ، الأسطح الخشبية ، الأثاث الشمعي.',
    'products_thirteen_ingredients':'4.0٪ كحول الأيزوبروبيل ، 1٪ إيثيلين جليكول مونوبوتيل إيثر ، 0.1٪ كبريتات لوريل الصوديوم (خافض للتوتر السطحي) ، الكالسيوم (ca) 0.01٪ ، بيروفوسفات رباعي الصوديوم (منعم الماء) ، 0.05٪ من 28٪ أمونيا ، 1٪ من محلول صبغ و 0.01٪ عطر.   .',
    'products_thirteen_safetyWarning':'يفضل استخدامة في 30 شهرًا الأولى من تاريخ الإنتاج الظاهر على العبوة. للاستخدام الخارجي فقط يجب تخزينه في مكان بارد وجاف لا يقل عن 30 درجة مئوية. يجب إبقائة بعيدا عن متناول الأطفال. غير صالح للأكل ، في حال حدوث تورم في الجلد ، ينصح بزيارة الطبيب على الفور.',


    'products_fourteen_title':'مطهر ومعقم 750 مل',
    'products_fourteen_desc':'سائل كير بلس مطهر ومعقم',
    'products_fourteen_dir':'قم بإستخدامة مركز للمراحيض والأحواض والمصارف. ومخفف للاستخدام الطبي والعناية الشخصية والغسيل',
    'products_fourteen_caution':'على الرغم من كونه مطهر لطيف ، لا  تستخدمة للأطفال إلا بإرشادات طبية.',
    'products_fourteen_ingredients':'الكلوروكسيلينول ، زيت الصنوبر ، كحول الآيزوبروبيل ، زيت الخروع ، الكراميل ، الماء منزوع الأيونات',
    'products_fourteen_safetyWarning':'فضل استخدامة في 30 شهرًا الأولى من تاريخ الإنتاج الظاهر على العبوة. للاستخدام الخارجي فقط قم بتخزينه في مكان بارد وجاف بدرجة حرارة لا تقل عن 30 درجة مئوية. قم بإبقائة بعيدا عن متناول الأطفال. غير صالح للأكل ، في حال ظهور إنتفاخات في الجلد ، ينصح بزيارة الطبيب فوراً.',


    'products_fifteen_title':'منظف ومطهر الارضيات',
    'products_fifteen_desc':'كير بلس ، سائل غسيل الأطباق مضاد للبكتيريا ، 1 لتر ، برائحة التفاح',
    'products_fifteen_dir':'سائل تنظيف مبتكر وعالمي متعدد الأغراض. مصمم لتنظيف الأسطح المختلفة ، بما في ذلك الأرضيات الخشبية ، المطلية ، الخزفية والبلاستيكية ، ألواح الأرضيات ، البلاط ، عتبات النوافذ ، أثاث المطبخ ، إلخ.',
    'products_fifteen_caution':'قم بحفظ المنتج بعيداً عن أشعة الشمس المباشرة',
    'products_fifteen_ingredients':'12-30٪ لابسا ، صوديوم ، هيدروكسيد ، صوديوم لوريل إيثر سلفات ،، خافض للتوتر غير الأيوني ، بيتاين بروبيل كوكاميد ، ، جليسرين ، بنزيسوثيازولينون ، ميثيل كلورويسوثيازولينون ، ميثيلسوثيازولينون ، عامل مضاد للجراثيم ، عطر ، صبغ ، ليمونين ، لينالول ، سيترال',
    'products_fifteen_safetyWarning':'يفضل استخدامة في 30 شهرًا الأولى من تاريخ الإنتاج الظاهر على العبوة. للاستخدام الخارجي فقط يجب تخزينه في مكان بارد وجاف لا يقل عن 30 درجة مئوية. يجب إبقائة بعيدا عن متناول الأطفال. غير صالح للأكل ، في حال حدوث تورم في الجلد ، ينصح بزيارة الطبيب على الفور.',



    'products_sixteen_title':'سائل غسيل الصحون -التفاح -1 لتر',
    'products_sixteen_desc':'كير بلس ، سائل غسيل الأطباق مضاد للبكتيريا ، 1 لتر ، برائحة التفاح',
    'products_sixteen_dir':'أضف القليل من سائل غسيل الصحون إلى وعاء صغير من الماء أو مباشرة على الإسفنجة وابدأ الغسيل للحصول على أطباق نظيفة ولامعة.',
    'products_sixteen_caution':'أبقة بعيداً عن أشعة الشمس المباشرة',
    'products_sixteen_ingredients':'12-30٪ لابسا، صوديوم ، هيدروكسيد ، صوديوم لوريل إيثر سلفات ، خافض للتوتر غير الأيوني ، بيتاين بروبيل كوكاميد ، جليسرين ، بنزيسوثيازولينون ، ميثيل كلورويسوثيازولينون ، ميثيلسوثيازولينون ، عامل مضاد للجراثيم ، عطر ، صبغ ، ليمونين ، لينالول ، سيترال',
    'products_sixteen_safetyWarning':'يفضل استخدامة في 30 شهرًا الأولى من تاريخ الإنتاج الظاهر على العبوة. للاستخدام الخارجي فقط يجب تخزينه في مكان بارد وجاف لا يقل عن 30 درجة مئوية. يجب إبقائة بعيدا عن متناول الأطفال. غير صالح للأكل ، في حال حدوث تورم في الجلد ، ينصح بزيارة الطبيب على الفور.',


    'products_seventeen_title':'سائل غسيل الصحون -التفاح -500 مل',
    'products_seventeen_desc':'كير بلس ، سائل غسيل الأطباق مضاد للبكتيريا ، 500 مل ، برائحة التفاح',
    'products_seventeen_dir':'أضف القليل من سائل غسيل الصحون إلى وعاء صغير من الماء أو مباشرة على الإسفنجة وابدأ الغسيل للحصول على أطباق نظيفة ولامعة.',
    'products_seventeen_caution':'أبقة بعيداً عن أشعة الشمس المباشرة',
    'products_seventeen_ingredients':'12-30٪ لابسا، صوديوم ، هيدروكسيد ، صوديوم لوريل إيثر سلفات ، خافض للتوتر غير الأيوني ، بيتاين بروبيل كوكاميد ، جليسرين ، بنزيسوثيازولينون ، ميثيل كلورويسوثيازولينون ، ميثيلسوثيازولينون ، عامل مضاد للجراثيم ، عطر ، صبغ ، ليمونين ، لينالول ، سيترال',
    'products_seventeen_safetyWarning':'يفضل استخدامة في 30 شهرًا الأولى من تاريخ الإنتاج الظاهر على العبوة. للاستخدام الخارجي فقط يجب تخزينه في مكان بارد وجاف لا يقل عن 30 درجة مئوية. يجب إبقائة بعيدا عن متناول الأطفال. غير صالح للأكل ، في حال حدوث تورم في الجلد ، ينصح بزيارة الطبيب على الفور.',


    'products_eightteen_title':'سائل غسيل الصحون - ليمون-5 لتر',
    'products_eightteen_desc':'كير بلس ، سائل غسيل الأطباق مضاد للبكتيريا ، 5 لتر ، برائحة الليمون',
    'products_eightteen_dir':'أضف القليل من سائل غسيل الصحون إلى وعاء صغير من الماء أو مباشرة على الإسفنجة وابدأ الغسيل للحصول على أطباق نظيفة ولامعة.',
    'products_eightteen_caution':'أبقة بعيداً عن أشعة الشمس المباشرة',
    'products_eightteen_ingredients':'12-30٪ لابسا، صوديوم ، هيدروكسيد ، صوديوم لوريل إيثر سلفات ، خافض للتوتر غير الأيوني ، بيتاين بروبيل كوكاميد ، جليسرين ، بنزيسوثيازولينون ، ميثيل كلورويسوثيازولينون ، ميثيلسوثيازولينون ، عامل مضاد للجراثيم ، عطر ، صبغ ، ليمونين ، لينالول ، سيترال',
    'products_eightteen_safetyWarning':'يفضل استخدامة في 30 شهرًا الأولى من تاريخ الإنتاج الظاهر على العبوة. للاستخدام الخارجي فقط  يجب تخزينه في مكان بارد وجاف لا يقل عن 30 درجة مئوية. يجب إبقائة بعيدا عن متناول الأطفال. غير صالح للأكل ، في حال حدوث تورم في الجلد ، ينصح بزيارة الطبيب على الفور.',


    'products_nineteen_title':'سائل غسيل الصحون - فراوله-5 لتر',
    'products_nineteen_desc':'كير بلس ، سائل غسيل الأطباق مضاد للبكتيريا ، 5 لتر ، برائحة الفراولة',
    'products_nineteen_dir':'أضف القليل من سائل غسيل الصحون إلى وعاء صغير من الماء أو مباشرة على الإسفنجة وابدأ الغسيل للحصول على أطباق نظيفة ولامعة.',
    'products_nineteen_caution':'أبقة بعيداً عن أشعة الشمس المباشرة',
    'products_nineteen_ingredients':'12-30٪ لابسا، صوديوم ، هيدروكسيد ، صوديوم لوريل إيثر سلفات ، خافض للتوتر غير الأيوني ، بيتاين بروبيل كوكاميد ، جليسرين ، بنزيسوثيازولينون ، ميثيل كلورويسوثيازولينون ، ميثيلسوثيازولينون ، عامل مضاد للجراثيم ، عطر ، صبغ ، ليمونين ، لينالول ، سيترال',
    'products_nineteen_safetyWarning':'يفضل استخدامة في 30 شهرًا الأولى من تاريخ الإنتاج الظاهر على العبوة. للاستخدام الخارجي فقط  يجب تخزينه في مكان بارد وجاف لا يقل عن 30 درجة مئوية. يجب إبقائة بعيدا عن متناول الأطفال. غير صالح للأكل ، في حال حدوث تورم في الجلد ، ينصح بزيارة الطبيب على الفور.',


    'products_twenty_title':'سائل غسيل الصحون -فراوله -500 مل',
    'products_twenty_desc':'كير بلس ، سائل غسيل الأطباق مضاد للبكتيريا ، 500 مل ، برائحة الفراولة',
    'products_twenty_dir':'أضف القليل من سائل غسيل الصحون إلى وعاء صغير من الماء أو مباشرة على الإسفنجة وابدأ الغسيل للحصول على أطباق نظيفة ولامعة.',
    'products_twenty_caution':'أبقة بعيداً عن أشعة الشمس المباشرة',
    'products_twenty_ingredients':'12-30٪ لابسا، صوديوم ، هيدروكسيد ، صوديوم لوريل إيثر سلفات ، خافض للتوتر غير الأيوني ، بيتاين بروبيل كوكاميد ، جليسرين ، بنزيسوثيازولينون ، ميثيل كلورويسوثيازولينون ، ميثيلسوثيازولينون ، عامل مضاد للجراثيم ، عطر ، صبغ ، ليمونين ، لينالول ، سيترال',
    'products_twenty_safetyWarning':'يفضل استخدامة في 30 شهرًا الأولى من تاريخ الإنتاج الظاهر على العبوة. للاستخدام الخارجي فقط  يجب تخزينه في مكان بارد وجاف لا يقل عن 30 درجة مئوية. يجب إبقائة بعيدا عن متناول الأطفال. غير صالح للأكل ، في حال حدوث تورم في الجلد ، ينصح بزيارة الطبيب على الفور.',


    'products_twentyone_title':'معقم لليدين 5 لتر - أصلي',
    'products_twentyone_desc':'ن معقم اليدين كير بلس يقتل 99.99٪ من الجراثيم على الفور دون الحاجة إلى ماء للتمتع بشعور الإنتعاش في يديك . احملها واستخدمها في أي وقت وأي مكان: السيارة ، المكتب ، النزهات ، تغيير الحفاضات ، التخييم ، السفر ، الرياضة إلخ.',
    'products_twentyone_dir':'اضغط على كمية صغيرة من معقم اليدين كير بلس في راحة يدك ثم افرك يديك معًا حتى يجف. يتم تخزينة في درجة حرارة أقل من 30 درجة مئوية.',
    'products_twentyone_caution':'قابل للاشتعال بقوة.  يجب إبقائة بعيداً عن النار واللهب. للاستخدام الخارجي فقط. في حالة ملامسة العين ، اغسلهما بالماء. توقف عن الاستخدام في حالة حدوث تهيج للجلد. إذا استمر التهيج ، استشر الطبيب. يحفظ بعيدا عن متناول الأطفال. في حال إبتلاع المنتج ، اتصل بمركز معلومات السموم.    ',
    'products_twentyone_ingredients':'أكريلات / C10-30 ألكيل أكريلات ، كروس بوليمر ، البولي ايثيلين جلايكول و بوليبروبيلين جليكول 17/16  كوبوليمر ، كحول دينات ، أكوا ، مونو بروبيلين جلايكول ، ثلاثي إيثامين أمين ، عطر ، لينالول ، هيكسيل سيناماي ، هيدروكسي سيترونيلاي ، ليمونين',
    'products_twentyone_safetyWarning':'يجب الابتعاد عن النار أو اللهب. للاستخدام الخارجي فقط. عند استخدام هذا المنتج لا تستخدم في أو بالقرب من العين. في حالة ملامسة العين ، اغسل العينين جيدًا بالماء. توقف عن الاستخدام في حال ظهور تهيج أو طفح جلدي أو استمرارة و أستشر الطبيب. يحفظ بعيدا عن متناول الأطفال. إذا ابتلع ، أطلب المساعدة الطبية أو اتصل بمركز مكافحة السموم على الفور.',


    'products_twentytwo_title':'معقم لليدين 50 مل - أصلي',
    'products_twentytwo_desc':'ن معقم اليدين كير بلس يقتل 99.99٪ من الجراثيم على الفور دون الحاجة إلى ماء للتمتع بشعور الإنتعاش في يديك . احملها واستخدمها في أي وقت وأي مكان: السيارة ، المكتب ، النزهات ، تغيير الحفاضات ، التخييم ، السفر ، الرياضة إلخ.',
    'products_twentytwo_dir':'اضغط على كمية صغيرة من معقم اليدين كير بلس في راحة يدك ثم افرك يديك معًا حتى يجف. يتم تخزينة في درجة حرارة أقل من 30 درجة مئوية.',
    'products_twentytwo_caution':'قابل للاشتعال بقوة.  يجب إبقائة بعيداً عن النار واللهب. للاستخدام الخارجي فقط. في حالة ملامسة العين ، اغسلهما بالماء. توقف عن الاستخدام في حالة حدوث تهيج للجلد. إذا استمر التهيج ، استشر الطبيب. يحفظ بعيدا عن متناول الأطفال. في حال إبتلاع المنتج ، اتصل بمركز معلومات السموم.    ',
    'products_twentytwo_ingredients':'أكريلات / C10-30 ألكيل أكريلات ، كروس بوليمر ، البولي ايثيلين جلايكول و بوليبروبيلين جليكول 17/16  كوبوليمر ، كحول دينات ، أكوا ، مونو بروبيلين جلايكول ، ثلاثي إيثامين أمين ، عطر ، لينالول ، هيكسيل سيناماي ، هيدروكسي سيترونيلاي ، ليمونين',
    'products_twentytwo_safetyWarning':'يجب الابتعاد عن النار أو اللهب. للاستخدام الخارجي فقط. عند استخدام هذا المنتج لا تستخدم في أو بالقرب من العين. في حالة ملامسة العين ، اغسل العينين جيدًا بالماء. توقف عن الاستخدام في حال ظهور تهيج أو طفح جلدي أو استمرارة و أستشر الطبيب. يحفظ بعيدا عن متناول الأطفال. إذا ابتلع ، أطلب المساعدة الطبية أو اتصل بمركز مكافحة السموم على الفور.',


    'products_twentythree_title':'معقم لليدين 600 مل - بالأعشاب',
    'products_twentythree_desc':'ن معقم اليدين كير بلس يقتل 99.99٪ من الجراثيم على الفور دون الحاجة إلى ماء للتمتع بشعور الإنتعاش في يديك . احملها واستخدمها في أي وقت وأي مكان: السيارة ، المكتب ، النزهات ، تغيير الحفاضات ، التخييم ، السفر ، الرياضة إلخ.',
    'products_twentythree_dir':'اضغط على كمية صغيرة من معقم اليدين كير بلس في راحة يدك ثم افرك يديك معًا حتى يجف. يتم تخزينة في درجة حرارة أقل من 30 درجة مئوية.',
    'products_twentythree_caution':'قابل للاشتعال بقوة.  يجب إبقائة بعيداً عن النار واللهب. للاستخدام الخارجي فقط. في حالة ملامسة العين ، اغسلهما بالماء. توقف عن الاستخدام في حالة حدوث تهيج للجلد. إذا استمر التهيج ، استشر الطبيب. يحفظ بعيدا عن متناول الأطفال. في حال إبتلاع المنتج ، اتصل بمركز معلومات السموم.    ',
    'products_twentythree_ingredients':'أكريلات / C10-30 ألكيل أكريلات ، كروس بوليمر ، البولي ايثيلين جلايكول و بوليبروبيلين جليكول 17/16  كوبوليمر ، كحول دينات ، أكوا ، مونو بروبيلين جلايكول ، ثلاثي إيثامين أمين ، عطر ، لينالول ، هيكسيل سيناماي ، هيدروكسي سيترونيلاي ، ليمونين',
    'products_twentythree_safetyWarning':'يجب الابتعاد عن النار أو اللهب. للاستخدام الخارجي فقط. عند استخدام هذا المنتج لا تستخدم في أو بالقرب من العين. في حالة ملامسة العين ، اغسل العينين جيدًا بالماء. توقف عن الاستخدام في حال ظهور تهيج أو طفح جلدي أو استمرارة و أستشر الطبيب. يحفظ بعيدا عن متناول الأطفال. إذا ابتلع ، أطلب المساعدة الطبية أو اتصل بمركز مكافحة السموم على الفور.',


    'products_button':'مشاهدة المزيد',
    'products_button_more':'مشاهدة المزيد من المنتجات',

    'clients_title':'عملاؤنا',

    'contact_title':'تواصل معنا',
    'contact_text':'أية استفسارات أو أسئلة يرجى ملئ النموذج التالي',
    'contact_form_name':'الاسم',
    'contact_form_email':'البريد الالكتروني',
    'contact_form_phone':'رقم الهاتف',
    'contact_form_message':'الرسالة',
    'contact_form_submit':'ارسال',

    'contact_address':'T5-033 ، منطقة سيف ، الشارقة - الإمارات',
    'contact_pobox':'ص.ب ',
    'contact_follow':'تابعونا',


    'footer_privacy':'سياسية الخصوصية',
    'footer_terms':'الأحكام والشروط',
    'footer_design':' صمم',
    'footer_designby':'بـ',
    'footer_company':'بلاك جم',
    'footer_copyright':'جميع الحقوق محفوظة',

    'otherproducts_title_one':'المنظفات المتعددة الإستخدام و مواد التنظيف',
    'otherproducts_title_one_item_one':'المبيضات',
    'otherproducts_title_one_item_two':'معطر المفروشات',
    'otherproducts_title_one_item_three':'المنظفات السائلة',
    'otherproducts_title_one_item_four':'منظف الزجاج والأسطح',
    'otherproducts_title_one_item_five':'المنظفات متعددة الوظائف',
    'otherproducts_title_one_item_six':'مسحوق التنظيف',
    'otherproducts_title_one_item_seven':'فلاش (حمض الهيدروكلوريك)',
    'otherproducts_title_one_item_eight':'هاربك',


    'otherproducts_title_two':'منتجات العناية بالسجاد والأرضيات',
    'otherproducts_title_two_item_one':'شامبو للسجاد والستائر',
    'otherproducts_title_two_item_two':'معطر المفروشات',
    'otherproducts_title_two_item_three':'المنظفات السائلة',
    'otherproducts_title_two_item_four':'منظف الزجاج والأسطح',


    'otherproducts_title_three':'منتجات العناية بالمطبخ',
    'otherproducts_title_three_item_one':'غسيل الصحون',
    'otherproducts_title_three_item_two':'منظف الخضار والفواكه',
    'otherproducts_title_three_item_three':'منظف الأفران',


    'otherproducts_title_four':'منتجات العناية بالغسيل',
    'otherproducts_title_four_item_one':'شامبو العباية',
    'otherproducts_title_four_item_two':'منعم الأقمشة',
    'otherproducts_title_four_item_three':'منظف الملابس البيضاء',


    'otherproducts_title_five':'المطهرات',
    'otherproducts_title_five_item_one':'معقم و مطهر',
    'otherproducts_title_five_item_two':'ملطف الجو',
    'otherproducts_title_five_item_three':'مطهر الأسطح',


    'otherproducts_title_six':'منتجات العناية الشخصية',
    'otherproducts_title_six_item_one':'سائل غسل اليدين',
    'otherproducts_title_six_item_two':'جل معقم لليدين',
    'otherproducts_title_six_item_three':'شامبو للشعر ضد القشرة',
    'otherproducts_title_six_item_four':'بلسم الشعر',
    'otherproducts_title_six_item_five':'شامبو الشعر للصالونات',
    'otherproducts_title_six_item_six':'بلسم الشعر للصالونات',
    'otherproducts_title_six_item_seven':'جل الشعر',
    'otherproducts_title_six_item_eight':'جل الحلاقة',
    'otherproducts_title_six_item_nine':'جل الإستحمام',


    'otherproducts_title_seven':'منتجات العناية بالسيارات',
    'otherproducts_title_seven_item_one':'شامبو لتنظيف السيارة',
    'otherproducts_title_seven_item_two':'ملمع الإطارات',

    'privacy_title':'سياسية الخصوصية',
    'privacy_title_one':'عام',
    'privacy_title_one_para_one':'تحترم شركة D.D.C Factory F.Z.C والشركات التابعة لها(“D.D.C Factory F.Z.C”) خصوصية كل فرد يزور مواقعنا أو يستجيب لإعلاناتنا التفاعلية والاتصالات الأخرى. يوضح بيان الخصوصية هذا المعلومات التي ستجمعها شركة D.D.C Factory F.Z.C وكيف سنستخدم تلك المعلومات. سيرشدك هذا البيان أيضًا إلى ما يجب فعله إذا كنت لا تريد جمع معلوماتك الشخصية أو مشاركتها عند زيارة مواقع D.D.C Factory F.Z.C أو الرد على إعلاناتنا والاتصالات الأخرى.',

    'privacy_title_one_para_two':'قد نقوم بتعديل بيان الخصوصية هذا وبالتالي يجب عليك زيارة هذه الصفحة بشكل دوري للتأكد من أنك قرأت وتوافق على أحدث بيان خصوصية لدينا.',

    'privacy_title_two':'معلومات التعرف الشخصية',
    'privacy_title_two_para_one':'لن تجمع D.D.C Factory F.Z.C أي معلومات تعريف شخصية عنك (أي اسمك أو عنوانك أو رقم هاتفك أو عنوان بريدك الإلكتروني) ما لم تقدم لنا طواعية.',
    
    'privacy_title_two_para_two':'عندما تقدم لنا معلومات تعريف شخصية ، قد نسأل عما إذا كنت ترغب في تلقي اتصالات مستقبلية منا ، مثل الرسائل الإخبارية أو البريد الإلكتروني أو معلومات أخرى. يمكنك اختيار عدم تلقي هذا الاتصال ، إذا كنت ترغب في ذلك.',

    'privacy_title_two_para_three':'ذا كنت لا تريد جمع معلومات التعريف الشخصية الخاصة بك ، فيرجى عدم إرسالها إلينا. إذا كنت قد أرسلت هذه المعلومات بالفعل وتريد منا إزالتها من سجلاتنا ، فالرجاء زيارة صفحة “اتصل بنا” على العلامة التجارية أو موقع الشركة على الويب.',

    'privacy_title_two_para_four':'بالإضافة إلى تزويدك بالخدمة أو المعلومات التي تطلبها ، يمكن أيضًا استخدام معلوماتك الشخصية لتحديد الهوية لمساعدتنا في تتبع الطريقة التي تستخدم بها موقعنا ومنتجاتنا. قد نقوم أيضًا بدمج المعلومات التي تقدمها مع المعلومات التي نجمعها بشكل منفصل ، بما في ذلك المعلومات التي قدمتها إلى المنظمات الأخرى (بموافقة أنه يمكن مشاركتها) بالإضافة إلى المعلومات العامة. ستساعدنا كل هذه المعلومات ، على سبيل المثال ، في تصميم أفضل لمنتجاتنا ومواقعنا وإعلاناتنا. للقيام بذلك ، قد نشارك أيضًا معلوماتك مع الشركات التي تقوم بعمل لنا. وافقت هذه الشركات على استخدام معلوماتك فقط بالطريقة التي نخبرها بها ووضع إجراءات لحماية المعلومات.',

    'privacy_title_two_para_five':'إذا شاركنا معلوماتك مع شركة ، بخلاف ما هو موضح أعلاه ، فسنتصل بك أولاً ونمنحك الفرصة لإزالة معلوماتك من قاعدة بياناتنا ما لم تكن قد وافقت بالفعل على ذلك.',

    'privacy_title_three':'الأطفال',
    'privacy_title_three_para_one':'لا تنوي شركة D.D.C Factory F.Z.C جمع أي معلومات تعريف شخصية (أي الاسم أو العنوان أو رقم الهاتف أو عنوان البريد الإلكتروني) من أفراد تقل أعمارهم عن واحد وعشرين عامًا. حيثما كان ذلك مناسبًا ، ستوجه شركة D.D.C Factory F.Z.C الأطفال على وجه التحديد بعدم تقديم مثل هذه المعلومات. إذا كان الطفل قد زودنا بمعلومات تحديد الهوية الشخصية ، فيجب على أحد الوالدين أو الوصي على ذلك الطفل الاتصال بنا عبر صفحة "اتصل بنا" على العلامة التجارية أو موقع الشركة إذا كان يرغب في حذف هذه المعلومات من سجلاتنا',
    
    'privacy_title_three_para_two':'يتم جمع معلومات التعريف غير الشخصية تلقائيًا.',

    'privacy_title_three_para_three':'في بعض الحالات ، قد نجمع معلومات عنك لا تحدد الهوية الشخصية. تتضمن الأمثلة على هذا النوع من المعلومات نوع متصفح الإنترنت الذي تستخدمه ، ونوع نظام تشغيل الكمبيوتر الذي تستخدمه ، واسم المجال للموقع الذي ربطت منه موقعنا أو إعلاننا. نستخدم هذه المعلومات لأغراض فهم أفضل لمصالح عملائنا وللمساعدة في تحسين أعمالنا ومواقعنا.',

    'privacy_title_three_para_four':'المعلومات التي قد نضعها تلقائيًا على القرص الصلب لجهاز الكمبيوتر الخاص بك.',

    'privacy_title_three_para_five':'عند عرض أحد مواقعنا أو إعلاناتنا ، قد نقوم بتخزين بعض المعلومات على جهاز الكمبيوتر الخاص بك. على سبيل المثال ، قد تكون هذه المعلومات في شكل "ملف تعريف ارتباط" أو ملف مشابه يسمح لنا بتخصيص موقع أو إعلان لمطابقة اهتماماتك وتفضيلاتك بشكل أفضل. مع معظم مستعرضات الإنترنت ، يمكنك مسح ملفات تعريف الارتباط من محرك الأقراص الثابتة بجهاز الكمبيوتر لديك ، أو حظر كافة ملفات تعريف الارتباط ، أو تلقي تحذير قبل تخزين ملف تعريف الارتباط. يرجى الرجوع إلى تعليمات المتصفح أو شاشة المساعدة لمعرفة المزيد حول هذه الوظائف. يرجى ملاحظة أن إزالة ملفات تعريف الارتباط أو حظرها قد تتداخل مع استخدامك الكامل لموقعنا.',
    

    'privacy_title_four':'لمن الاتصال',
    'privacy_title_four_para_one':'إذا كنت قد قدمت معلومات تحديد الهوية الشخصية من خلال موقع D.D.C Factory F.Z.C أو إعلان تفاعلي أو أي اتصال آخر ، وتريد حذف هذه المعلومات من سجلاتنا ، يرجى زيارة صفحة "اتصل بنا" على العلامة التجارية أو موقع الشركة على الويب.',


    'terms_title':'الأحكام والشروط',
    'terms_title_two':'إشعار قانوني هام',

    'terms_para_one':'الاهتمام بالوصول إلى هذا الموقع واستخدامه وجميع العلامات التجارية ومواقع الويب الخاصة بشركة D.D.C Factory (المشار إليها فيما يلي باسم "الموقع / المواقع") تخضع للشروط والأحكام التالية وجميع القوانين المعمول بها. من خلال عرض هذا الموقع ، فإنك توافق على شروطه ؛ إذا كنت لا توافق على شروطه ، لا تشاهد هذا الموقع. قد تحتوي مواقعنا على شروط وأحكام إضافية تسري على استخدام تلك المواقع. في حالة وجود أي تعارض بين الشروط والأحكام الواردة هنا والبنود الإضافية على أي من مواقعنا ، فإن هذه الشروط تكون لها الأسبقية. تحتفظ شركة D.D.C Factory F.Z.C Brands بالحق في مراجعة هذه الشروط والأحكام و / أو إنهاء الوصول دون إشعار إلى المواقع في أي وقت. يجب عليك التحقق من المواقع من وقت لآخر للتحقق من الإشعار القانوني الحالي ثم الشروط والأحكام الإضافية الأخرى ، لأنها ملزمة لك.' ,

    'terms_para_two':'يمكنك تصفح هذا الموقع للترفيه والمعلومات الشخصية. يمكنك التنزيل على جهاز كمبيوتر واحد أو طباعة أي مواد على الموقع لاستخدامك غير التجاري أو التعليمي أو الخاص أو المحلي فقط. ومع ذلك ، لا يجوز لك توزيع محتويات هذا الموقع أو تعديلها أو نقلها أو مراجعتها دون إذن خطي من D.D.C Factory F.Z.C ومواقع الويب. لا يجوز لك الارتباط بالموقع من موقع طرف ثالث دون إذن كتابي مسبق منا.',

    'terms_para_three':'ما لم ينص على خلاف ذلك ، فإن حقوق الطبع والنشر وحقوق الملكية الفكرية الأخرى في جميع المواد على الموقع (بما في ذلك على سبيل المثال لا الحصر الصور الفوتوغرافية والصور الرسومية) مملوكة لشركة D.D.C Factory F.Z.C أو المرخصين لها. لا يتم نقل الملكية أو حقوق الملكية الفكرية إلى أي طرف ثالث من خلال استخدام أو الوصول إلى هذا الموقع ؛ بدلاً من ذلك ، تظل جميع الحقوق والملكية والفائدة في جميع جوانب هذا الموقع ملكية حصرية لشركة D.D.C Factory F.Z.C أو المرخصين لها. مواقع شركة D.D.C Factory F.Z.C محمية بموجب قوانين حقوق النشر والقوانين الأخرى المعمول بها. إذا انتهكت أيًا من الشروط الواردة في هذا الإشعار القانوني أو أي مواقع ، فسيتم إنهاء إذنك لاستخدام المواقع تلقائيًا. محجوزة أي حقوق لا الممنوحة صراحة فيه.',

    'terms_para_four':'إذا كنت ترغب في التواصل رسميًا مع شركة D.D.C Factory F.Z.C بشأن مسألة قانونية ، يرجى الاتصال بشركة localD.D.C Factory F.Z.C. يحتوي موقع شركتنا في DDCIF.com على قائمة بشركاتنا المحلية.   ',

    'terms_para_five':'يتم توفير المواد الموجودة على الموقع "كما هي" ، دون أي شروط أو ضمانات أو إقرارات أو شروط أخرى من أي نوع. لا تقدم شركة D.D.C Factory F.Z.C أي ضمانات أو إقرارات حول دقة أو اكتمال محتوى هذا الموقع أو أي موقع (مواقع) "مرتبطة بشكل مباشر" بهذا الموقع. يجوز لشركة D.D.C Factory F.Z.C إجراء تغييرات على المحتوى في أي وقت دون إشعار. قد تكون المواد الموجودة على الموقع قديمة ، ولا تلتزم شركة D.D.C Factory F.Z.C بتحديث هذه المواد. وفقًا لذلك ، إلى أقصى حد يسمح به القانون ، تزودك DDC Factory FZC بالموقع على أساس أن DDC Factory FZC تستثني جميع الإقرارات والضمانات والشروط وغيرها من الشروط ، والتي ، ولكن بالنسبة لهذا الإشعار القانوني قد يكون لها تأثير فيما يتعلق الموقع.',

    'terms_para_six':'لا تهدف المواقع المصممة لتوفير معلومات حول منتجات الرعاية الصحية لدينا أو الأدوية المرخصة إلى تقديم المشورة الطبية أو التعليمات المتعلقة بالاستخدام ولكن فقط كمعلومات عامة لا ينبغي الاعتماد عليها من قبل أي فرد أو لأي غرض محدد. استشر طبيبك أو الصيدلي دائمًا للحصول على المشورة بشأن علاج الظروف والاحتياجات الفردية.',

    'terms_para_seven':'DDC Factory FZC (بما في ذلك أي من الشركات التابعة لها وأي ضباط أو مدراء أو موظفين أو مساهمين أو وكلاء لشركة DDC Factory FZC والشركات التابعة لها) لن تكون مسؤولة عن أي أضرار مباشرة أو غير مباشرة أو عرضية أو تبعية أو عقابية تنشأ عن: ) القدرة (أو عدم القدرة) على الوصول إلى هذا الموقع ، (ب) استخدام (أو عدم القدرة على استخدام) أي محتوى من هذا الموقع ، أو (ج) محتوى أي موقع (مواقع) "مرتبطة بشكل مباشر" بهذا الموقع. بالإضافة إلى ذلك ، لا تضمن شركة D.D.C Factory F.Z.C أن هذا الموقع أو الخادم الذي يوفره خالٍ من الفيروسات أو المكونات الضارة الأخرى ؛ تتحمل أنت (وليس DDC Factory FZC) التكلفة الكاملة لجميع الخدمات أو الإصلاح أو التصحيح الضرورية ، ويجب أن يقتصر أي مكافأة في أي إجراءات ذات صلة على الضرر المالي ولن تتضمن أي أمر قضائي أو توجيه إلى أي طرف بخلاف اتجاه الدفع مبلغ نقدي.',

    'terms_para_eight':'من خلال النشر أو الإرسال إلى هذا الموقع عبر الإنترنت ، فإنك تضمن لشركة D.D.C Factory F.Z.C أن المادة ستعامل وستكون غير سرية وغير مملوكة. من خلال إرسال أو نشر أي اتصال أو مادة على هذا الموقع ، فإنك توافق على أنه يجوز لشركة D.D.C Factory F.Z.C وأي من الشركات التابعة لها استخدام اتصالاتك و / أو المواد الخاصة بك لأي غرض ، بما في ذلك النسخ ، النقل ، النشر ، البث ، النشر. شركة D.D.C Factory F.Z.C ليست ملزمة بالرد على الرسائل المنشورة على هذا الموقع ، ولا تحتاج شركة DDC Factory F.Z.C إلى تقديم أي تعويض عن مثل هذه الاتصالات و / أو المواد. يرجى العلم بأن القانون الوطني المعمول به يحكم نوع المواد التي يمكن نشرها على موقع الويب ؛ ويجب عليك عدم مخالفة أي من هذه القوانين بما في ذلك تلك التي تحظر التهديد أو القذف أو التشهير أو الفاحشة أو المواد الإباحية أو غير اللائقة أو الدنيئة ، أو نشر أي مادة يمكن أن تشكل أو تشجع سلوكًا يمكن اعتباره جريمة جنائية أو مخالفة لأي قانون آخر القانون الواجب التطبيق.',

    'terms_para_nine':'إن شركة D.D.C Factory F.Z.C غير قادرة ولا تتحمل أي مسؤولية عن مراجعة الاتصالات أو المواد المنشورة على مواقعها. ومع ذلك ، تحتفظ شركة D.D.C Factory F.Z.C بالحق ، وفقًا لتقديرها الخاص ، في إزالة أي مراسلات أو مواد تعتبرها غير مقبولة لأي سبب من الأسباب.',

    'terms_para_ten':'تهدف شركة D.D.C Factory F.Z.C فقط إلى استخدام الأسماء أو الشعارات أو العلامات التي تظهر في هذا الموقع في تلك المناطق التي يحق لها أو لشركاتها التابعة لها القيام بذلك ، سواء بموجب العلامات التجارية أو التراخيص المعلقة أو المسجلة أو غير ذلك. لتجنب الشك ، لا تدعي DDC Factory FZC استخدام أي اسم أو شعار أو علامة في أي منطقة لا يحق لها فيها ، ولن تقدم أو تعرض توريد منتجات و / أو خدمات تحمل أي اسم أو شعار أو علامة في أي إقليم من هذا القبيل. يُحظر تمامًا استخدام أو إساءة استخدام هذه العلامات التجارية أو أي محتوى آخر على هذا الموقع ، باستثناء ما هو منصوص عليه في هذه الشروط والأحكام أو في محتوى الموقع.',

    'terms_para_eleven':'يخضع استخدامك لهذا الموقع من جميع النواحي لقوانين دولة الإمارات العربية المتحدة. يجب أن يبدأ أي سبب دعوى أو مطالبة قانونية قد تكون لديك فيما يتعلق بهذا الموقع في غضون 12 شهرًا من سبب الدعوى الناشئة.',

    'terms_para_twelve':'تسري هذه الشروط والأحكام اعتبارًا من 1 مارس 2020.',

    'terms_para_thirteen':'معمل D.D.C Factory F.Z.C. كل الحقوق محفوظة.',

    'terms_para_fourteen':'معمل D.D.C Factory F.Z.C',

    'terms_para_fifteen':'ص.ب ب 9100',

    'terms_para_sixteen':'منطقة سيف  زون ، T5-033',

    'terms_para_seventeen':'الشارقة,',

    'terms_para_eightteen':'الامارات العربية المتحده',
  },
};
