import React, { Component } from "react";
import HeroHeader from "./HeroHeader";
import AboutUs from "./AboutUs";
import Services from "./Services";
import Counter from "./Counter";
import Contact from "./Contact";
import Clients from "./Clients";
import Products from "./Products";

export class Home extends Component {
  render() {
    return (
      <div id="home">
        <HeroHeader />

        <AboutUs />

        <Services />

        <Counter />

        <Products />

        <Clients />

        <Contact />
      </div>
    );
  }
}

export default Home;
