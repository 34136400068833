import product1 from "../../media/Products/Antiseptic500ml.JPG";
import product2 from "../../media/Products/Antiseptic5L.JPG";
import product3 from "../../media/Products/cloromax_small.JPG";
import product4 from "../../media/Products/cloromax.JPG";
import product5 from "../../media/Products/flash1L_original.png";
import product6 from "../../media/Products/flash1L_flowerPerfume.png";
import product7 from "../../media/Products/dishwash_orange.JPG";
import product8 from "../../media/Products/dishwash_lemon.JPG";
import product9 from "../../media/Products/handSanitizer500ml_original.JPG";
import product10 from "../../media/Products/handSanitizer500ml_herbal.png";
import product11 from "../../media/Products/handSanitizer60ml_original.JPG";
import product12 from "../../media/Products/GlassCleaner650ml.JPG";
import product13 from "../../media/Products/GlassCleaner5L.jpg";
import product14 from "../../media/Products/Antiseptic750ml.JPG";
import product15 from "../../media/Products/floor_cleaner2.JPG";
import product16 from "../../media/Products/dishwash_apple.JPG";
import product17 from "../../media/Products/dishwash_apple500ml.JPG";
import product18 from "../../media/Products/dishwash_lemon_5L.JPG";
import product19 from "../../media/Products/dishwash_strawberry1L.jpg";
import product20 from "../../media/Products/dishwash_strawberry500ml.JPG";
import product21 from "../../media/Products/handSanitizer5L_original.JPG";
import product22 from "../../media/Products/handSanitizer50ml_original.JPG";
import product23 from "../../media/Products/handSanitizer600ml_herbal.JPG";
import translate from "../../i18nProvider/translate";


export default [
  {
    id: 1,
    title: translate('products_one_title'),
    description: translate('products_one_desc'),
    directions:translate('products_one_dir'),
    caution:translate('products_one_caution'),
    img: product1,
    social: { fa: "", tw: "" },
    ingredients:translate('products_one_ingredients'),
    safetyWarning:translate('products_one_safetyWarning'),
  },
  {
    id: 2,
    title: translate('products_two_title'),
    description: translate('products_two_desc'),
    directions:translate('products_two_dir'),
    caution:translate('products_two_caution'),
    img: product2,
    social: { fa: "", tw: "" },
    ingredients:translate('products_two_ingredients'),
    safetyWarning:translate('products_two_safetyWarning'),
  },

  {
    id: 3,
    title: translate('products_three_title'),
    description:translate('products_three_desc'),
    directions:translate('products_three_dir'),
    caution:translate('products_three_caution'),
    img: product3,
    social: { fa: "", tw: "" },
    ingredients:translate('products_three_ingredients'),
    safetyWarning:translate('products_three_safetyWarning'),
  },

  {
    id: 4,
    title:translate('products_four_title'),
    description: translate('products_four_desc'),
    directions:translate('products_four_dir'),
    caution:translate('products_four_caution'),
    img: product4,
    social: { fa: "", tw: "" },
    ingredients:translate('products_four_ingredients'),
    safetyWarning:translate('products_four_safetyWarning'),
  },

  {
    id: 5,
    title: translate('products_five_title'),
    description:translate('products_five_desc'),
    directions:translate('products_five_dir'),
    caution:translate('products_five_caution'),
    img: product5,
    social: { fa: "", tw: "" },
    ingredients:translate('products_five_ingredients'),
    safetyWarning:translate('products_five_safetyWarning'),
  },

  {
    id: 6,
    title: translate('products_six_title'),
    description:translate('products_six_desc'),
    directions:translate('products_six_dir'),
    caution:translate('products_six_caution'),
    img: product6,
    social: { fa: "", tw: "" },
    ingredients:translate('products_six_ingredients'),
    safetyWarning:translate('products_six_safetyWarning'),
  },

  {
    id: 7,
    title: translate('products_seven_title'),
    description:translate('products_seven_desc'),
    directions:translate('products_seven_dir'),
    caution: translate('products_seven_caution'),
    img: product7,
    social: { fa: "", tw: "" },
    ingredients:translate('products_seven_ingredients'),
    safetyWarning:translate('products_seven_safetyWarning'),
  },

  {
    id: 8,
    title: translate('products_eight_title'),
    description:translate('products_eight_desc'),
    directions:translate('products_eight_dir'),
    caution: translate('products_eight_caution'),
    img: product8,
    social: { fa: "", tw: "" },
    ingredients:translate('products_eight_ingredients'),
    safetyWarning:translate('products_eight_safetyWarning'),
  },

  {
    id: 9,
    title: translate('products_nine_title'),
    description: translate('products_nine_desc'),
    directions:translate('products_nine_dir'),
    caution: translate('products_nine_caution'),
    img: product9,
    social: { fa: "", tw: "" },
    ingredients:translate('products_nine_ingredients'),
    safetyWarning:translate('products_nine_safetyWarning'),
  },

  {
    id: 10,
    title: translate('products_ten_title'),
    description: translate('products_ten_desc'),
    directions:translate('products_ten_dir'),
    caution: translate('products_ten_caution'),
    img: product10,
    social: { fa: "", tw: "" },
    ingredients:translate('products_ten_ingredients'),
    safetyWarning:translate('products_ten_safetyWarning'),
  },

  {
    id: 11,
    title: translate('products_eleven_title'),
    description: translate('products_eleven_desc'),
    directions:translate('products_eleven_dir'),
    caution:translate('products_eleven_caution'),
    img: product11,
    social: { fa: "", tw: "" },
    ingredients:translate('products_eleven_ingredients'),
    safetyWarning:translate('products_eleven_safetyWarning'),
  },

  {
    id: 12,
    title: translate('products_twelve_title'),
    description:translate('products_twelve_desc'),
    directions:translate('products_twelve_dir'),
    caution:translate('products_twelve_caution'),
    img: product12,
    social: { fa: "", tw: "" },
    ingredients:translate('products_twelve_ingredients'),
    safetyWarning:translate('products_twelve_safetyWarning'),
  },

  {
    id: 13,
    title: translate('products_thirteen_title'),
    description:translate('products_thirteen_desc'),
    directions:translate('products_thirteen_dir'),
    caution:translate('products_thirteen_caution'),
    img: product13,
    social: { fa: "", tw: "" },
    ingredients:translate('products_thirteen_ingredients'),
    safetyWarning:translate('products_thirteen_safetyWarning'),
  },
  {
    id: 14,
    title: translate('products_fourteen_title'),
    description: translate('products_fourteen_desc'),
    directions:translate('products_fourteen_dir'),
    caution:translate('products_fourteen_caution'),
    img: product14,
    social: { fa: "", tw: "" },
    ingredients:translate('products_fourteen_ingredients'),
    safetyWarning:translate('products_fourteen_safetyWarning'),
  },
  {
    id: 15,
    title: translate('products_fifteen_title'),
    description:translate('products_fifteen_desc'),
    directions:translate('products_fifteen_dir'),
    caution: translate('products_fifteen_caution'),
    img: product15,
    social: { fa: "", tw: "" },
    ingredients:translate('products_fifteen_ingredients'),
    safetyWarning:translate('products_fifteen_safetyWarning'),
  },
  {
    id: 16,
    title: translate('products_sixteen_title'),
    description:translate('products_sixteen_desc'),
    directions:translate('products_sixteen_dir'),
    caution: translate('products_sixteen_caution'),
    img: product16,
    social: { fa: "", tw: "" },
    ingredients:translate('products_sixteen_ingredients'),
    safetyWarning:translate('products_sixteen_safetyWarning'),
  },
  {
    id: 17,
    title: translate('products_seventeen_title'),
    description:translate('products_seventeen_desc'),
    directions:translate('products_seventeen_dir'),
    caution: translate('products_seventeen_caution'),
    img: product17,
    social: { fa: "", tw: "" },
    ingredients:translate('products_seventeen_ingredients'),
    safetyWarning:translate('products_seventeen_safetyWarning'),
  },
  {
    id: 18,
    title: translate('products_eightteen_title'),
    description:translate('products_eightteen_desc'),
    directions:translate('products_eightteen_dir'),
    caution: translate('products_eightteen_caution'),
    img: product18,
    social: { fa: "", tw: "" },
    ingredients:translate('products_eightteen_ingredients'),
    safetyWarning:translate('products_eightteen_safetyWarning'),
  },
  {
    id: 19,
    title: translate('products_nineteen_title'),
    description:translate('products_nineteen_desc'),
    directions:translate('products_nineteen_dir'),
    caution: translate('products_nineteen_caution'),
    img: product19,
    social: { fa: "", tw: "" },
    ingredients:translate('products_nineteen_ingredients'),
    safetyWarning:translate('products_nineteen_safetyWarning'),
    },
  {
    id: 20,
    title: translate('products_twenty_title'),
    description:translate('products_twenty_desc'),
    directions:translate('products_twenty_dir'),
    caution: translate('products_twenty_caution'),
    img: product20,
    social: { fa: "", tw: "" },
    ingredients:translate('products_twenty_ingredients'),
    safetyWarning:translate('products_twenty_safetyWarning'),
    },
  
  {
    id: 21,
    title:translate('products_twentyone_title'),
    description:translate('products_twentyone_desc'),
    directions:translate('products_twentyone_dir'),
    caution:translate('products_twentyone_caution'),
    img: product21,
    social: { fa: "", tw: "" },
    ingredients:translate('products_twentyone_ingredients'),
    safetyWarning:translate('products_twentyone_safetyWarning'),
  },
  {
    id: 22,
    title: translate('products_twentytwo_title'),
    description:translate('products_twentytwo_desc'),
    directions:translate('products_twentytwo_dir'),
    caution:translate('products_twentytwo_caution'),
    img: product22,
    social: { fa: "", tw: "" },
    ingredients:translate('products_twentytwo_ingredients'),
    safetyWarning:translate('products_twentytwo_safetyWarning'),
  },
  {
    id: 23,
    title: translate('products_twentythree_title'),
    description:translate('products_twentythree_desc'),
    directions:translate('products_twentythree_dir'),
    caution:translate('products_twentythree_caution'),
    img: product23,
    social: { fa: "", tw: "" },
    ingredients:translate('products_twentythree_ingredients'),
    safetyWarning:translate('products_twentythree_safetyWarning'),
  },
];
