import React, { Component } from "react";
import Video from "../../media/video/dcc_2~1.webm";
import downArrow from "../../media/arrows/thin-arrowheads.png";
import { HashLink as Link } from "react-router-hash-link";
import translate from "../../i18nProvider/translate";


export default class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      videoURL: Video,
    };
  }
  render() {
    return (
      <div className="wrapper11">
        <video
          preload="auto"
          loop
          autoplay=""
          muted
          playsinline=""
          poster="/assets/poster.png"
          tabindex="-1"
        >
          <source src={this.state.videoURL} type="video/webm" />
        </video>

        <div className="thisTitle">
          <div className="titleBorder"></div>
          <h1>D.D.C<br/> {translate('main_heading')}</h1>{" "}
          
          <p>{translate('main_heading_paragraph')}</p>
          {/* <div id="downButton" className="downButton">
            <Link to="/#services">
              <img src={downArrow} alt="" />
            </Link>
          </div> */}

          
            
              
              <div id="downButton" className="mainButton" >
            <Link to="/#ourProducts">
              <button >{translate('main_heading_button')}</button>
            </Link>
          </div>

        </div>
        
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#a2d9ff" fill-opacity="1" d="M0,64L120,101.3C240,139,480,213,720,208C960,203,1200,117,1320,74.7L1440,32L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"></path></svg>
        
      </div>
    );
  }
}

{
  /* <video autoplay loop muted class="wrapper__video">
                <source src={this.state.videoURL} type="video/mp4" />
                <source src={this.state.videoURL} type="video/ogg" />
                    Your browser does not support the video tag.
                </video> */
}
