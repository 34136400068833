import React, { Component } from "react";
import privacyArray from "../arrays/privacyParagraphs";
import translate from "../../i18nProvider/translate";

export default class Privacy extends Component {
  componentDidMount() {
    this.scrollTop();
  }

  scrollTop() {
    // this method just scrolls the page back to the top smoothly.
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }

  render() {
    return (
      <div className="privacy">
        <div className="headingLeftCopyrights">
          <h2>{translate('privacy_title')}</h2>
          <div className="borderBottom"></div>
        </div>
        <div className="wholePrivacy">
          {/* <h3>GENERAL</h3> */}
          {privacyArray.map(item => {
            return <p>{item.para}</p>;
          })}
        </div>
      </div>
    );
  }
}
