import React, { Component } from "react";
import { HashLink as Link } from "react-router-hash-link";
import translate from "../../i18nProvider/translate";

// change footer to a stateless component
export class Footer extends Component {
  render() {
    return (
      <footer>
        {/* <div className="footerAddress">
          <Link to="/" exact="true">
            <img id="footerLogo" src="" alt="logo" />
          </Link>
        </div> */}

     
        <ul className="copyrights">
          <Link to="/privacyPolicy/">
            <li>{translate('footer_privacy')}</li>
          </Link>
          <Link to="/termsAndConditions/">
            <li>{translate('footer_terms')}</li>
          </Link>
         
          {/* <a href=""> */}
            <li>
              {translate('footer_design')} <span
               style={{
                color: "rgb(243, 77, 60)",
                fontSize: "1em",
              }}>&#10084;</span> <span>{translate('footer_designby')}</span>{" "}
              {/* <a
                href=""
                style={{
                  color: "rgb(243, 77, 60)",
                  padding: "0",
                  margin: "0",
                  fontSize: "1em",
                }}
              >
                KoalaTeam
              </a>{" "}
              in{" "} */}
              <a
                href="https://blackgem.net/"
                target="_blank"
                style={{
                  color: "#000",
                  padding: "0",
                  margin: "0",
                  fontWeight:"bold",
                  fontSize: "1em",
                }}
              >
               {translate('footer_company')}
              </a>{" "}
              © {new Date().getFullYear()} - {translate('footer_copyright')}
            </li>
          {/* </a> */}
        </ul>
      </footer>
    );
  }
}

export default Footer;
