import React, { Component } from "react";
import headImg from "../../media/Products/products.svg";
import translate from "../../i18nProvider/translate";

export default class OtherProducts extends Component {
  componentDidMount() {
    this.scrollTop();
  }

  scrollTop() {
    // this method just scrolls the page back to the top smoothly.
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }

  render() {
    return (
      <div className="otherProducts">
        <div className="heading">
          {/* <h1>OUR FAMILY PRODUCTS</h1> */}
          <div className="borderBottom"></div>
        </div>
        <div className="preImage">
          <img
            src={headImg}
            alt=""
          />
        </div>



        <div className="wholeOtherReverse">
          <div style={{ display: 'flex' }}>
            <div style={{ paddingRight: "1em" }}>
              <h3 style={{ color: "#169db1", paddingLeft: '1em' }}>{translate('otherproducts_title_one')}</h3>
              <div className="singleOtherBig">
                <ul>
                  <li>{translate('otherproducts_title_one_item_one')}</li>
                  <li>{translate('otherproducts_title_one_item_two')}</li>
                  <li>{translate('otherproducts_title_one_item_three')}</li>
                  <li>{translate('otherproducts_title_one_item_four')}</li>
                </ul>
                <ul>
                  {/* <h3 style={{ visibility: "hidden" }}>a</h3> */}
                  <li>{translate('otherproducts_title_one_item_five')}</li>
                  <li>{translate('otherproducts_title_one_item_six')}</li>
                  <li>{translate('otherproducts_title_one_item_seven')}</li>
                  <li>{translate('otherproducts_title_one_item_eight')}</li>
                </ul>
              </div>
            </div>
            <div className="borderRight" style={styles}></div>
          </div>

          <div>
            <h3 style={{ color: "#169db1", paddingLeft: '1em' }}>{translate('otherproducts_title_two')}</h3>
            <div className="singleOther">
              <ul>
                  <li>{translate('otherproducts_title_two_item_one')}</li>
                  <li>{translate('otherproducts_title_two_item_two')}</li>
                  <li>{translate('otherproducts_title_two_item_three')}</li>
                  <li>{translate('otherproducts_title_two_item_four')}</li>
              </ul>
              <div className="borderRight" style={styles}></div>
            </div>
          </div>

        </div>


        <div className="wholeOther">

          <div style={{ display: 'flex' }}>
            <div style={{ paddingRight: "2em" }}>
              <h3 style={{ color: "#169db1", paddingLeft: '1em' }}>{translate('otherproducts_title_three')}</h3>
              <div className="singleOther1">
                <ul>
                  <li>{translate('otherproducts_title_three_item_one')}</li>
                  <li>{translate('otherproducts_title_three_item_two')}</li>
                  <li>{translate('otherproducts_title_three_item_three')}</li>
                </ul>
              </div>
            </div>
            <div className="borderRight" style={styles}></div>

          </div>


          <div style={{ display: 'flex' }}>
            <div style={{ paddingRight: "2em" }}>
              <h3 style={{ color: "#169db1", paddingLeft: '1em' }}>{translate('otherproducts_title_four')}</h3>
              <div className="singleOther1">
                <ul>
                  <li>{translate('otherproducts_title_four_item_one')}</li>
                  <li>{translate('otherproducts_title_four_item_two')}</li>
                  <li>{translate('otherproducts_title_four_item_three')}</li>
                </ul>
              </div>
            </div>
            <div className="borderRight" style={styles}></div>

          </div>


          <div style={{ display: 'flex' }}>
            <div style={{ paddingRight: "1em" }}>
              <h3 style={{ color: "#169db1", paddingLeft: '1em' }}>{translate('otherproducts_title_five')}</h3>
              <div className="singleOther">
                <ul>
                  <li>{translate('otherproducts_title_five_item_one')}</li>
                  <li>{translate('otherproducts_title_five_item_two')}</li>
                  <li>{translate('otherproducts_title_five_item_three')}</li>
                </ul>
              </div>
            </div>
            {/* <div className="borderRight" style={styles}></div> */}

          </div>

        </div>

        <div className="wholeOtherReverse">
          <div style={{ display: 'flex' }}>
            <div style={{ paddingRight: "1em" }}>
              <h3 style={{ color: "#169db1", paddingLeft: '1em' }}>{translate('otherproducts_title_six')}</h3>
              <div className="singleOtherBig">
                <ul>
                  <li>{translate('otherproducts_title_six_item_one')}</li>
                  <li>{translate('otherproducts_title_six_item_two')}</li>
                  <li>{translate('otherproducts_title_six_item_three')}</li>
                  <li>{translate('otherproducts_title_six_item_four')}</li>
                  <li>{translate('otherproducts_title_six_item_five')}</li>
                </ul>
                <ul>
                  {/* <h3 style={{ visibility: "hidden" }}>a</h3> */}
                  <li>{translate('otherproducts_title_six_item_six')}</li>
                  <li>{translate('otherproducts_title_six_item_seven')}</li>
                  <li>{translate('otherproducts_title_six_item_eight')}</li>
                  <li>{translate('otherproducts_title_six_item_nine')}</li>
                </ul>
              </div>
            </div>
            <div className="borderRight" style={styles}></div>
          </div>


          <div style={{ display: 'flex' }}>
            <div style={{ paddingRight: "2em" }}>
              <h3 style={{ color: "#169db1", paddingLeft: '1em' }}>{translate('otherproducts_title_seven')}</h3>
              <div className="singleOther1">
                <ul>
                  <li>{translate('otherproducts_title_seven_item_one')}</li>
                  <li>{translate('otherproducts_title_seven_item_two')}</li>
                </ul>
              </div>
            </div>
            {/* <div className="borderRight" style={styles}></div> */}

          </div>

        </div>

      </div>



    );
  }
}

const styles = {
  borderRightColor: "#169db1",
  height: "16vh",
};
